import { ITournament, SELECT_ORDER_OPTIONS, TournamentOrder, TournamentStatus } from 'app/types/tournament.interface';
import moment from 'moment/moment';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { PropsValue } from 'react-select';

import { useRootContext } from '~app/contexts/RootContext';
import { BracketType, IBracket } from '~app/types/bracket.interface';

import { request } from '../utils';

interface ITournamentFilter {
    search: string;
    status: { value: TournamentStatus | -1; label: string };
    type: { value: number; label: string };
    order: { value: TournamentOrder; label: string };
    dateBegin: Date | null;
}

interface TournamentsContextProps {
    tournaments: ITournament[] | null;
    tournament: ITournament | null;
    currentBracket: IBracket | null;
    setCurrentBracket: React.Dispatch<React.SetStateAction<IBracket | null>>;
    currentRoundId: string | null;
    setCurrentRoundId: React.Dispatch<React.SetStateAction<string | null>>;
    fetchTournaments: () => Promise<void>;
    fetchTournament: (slug: string) => Promise<void>;
    filter: ITournamentFilter;
    updateFilter: (
        field: keyof ITournamentFilter,
        value: string | Date | PropsValue<{ value: number; label: string }> | null,
    ) => void;
    clearTournamentsData: () => void;
}

const DEFAULT_FILTER_STATE = {
    search: '',
    status: { value: -1, label: 'All' },
    type: { value: -1, label: 'All' },
    order: SELECT_ORDER_OPTIONS[0],
    dateBegin: null,
};

export const TournamentsContext = createContext<TournamentsContextProps>({} as TournamentsContextProps);

export const TournamentsContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const { showLoader } = useRootContext();

    const [originalTournaments, setOriginalTournaments] = useState<ITournament[] | null>(null);
    const [tournaments, setTournaments] = useState<ITournament[] | null>(null);
    const [tournament, setTournament] = useState<ITournament | null>(null);
    const [filter, setFilter] = useState<ITournamentFilter>(DEFAULT_FILTER_STATE);
    const [currentBracket, setCurrentBracket] = useState<IBracket | null>(null);
    const [currentRoundId, setCurrentRoundId] = useState<string | null>(null);

    const doFilterTournaments = (f: ITournamentFilter, oTournaments: ITournament[] | null) => {
        if (!oTournaments) {
            setTournaments(null);

            return;
        }

        const filteredTournaments = oTournaments
            .filter((_) => {
                if (f.search) {
                    return _.title.toLowerCase().includes(f.search.toLowerCase());
                }

                return true;
            })
            .filter((_) => {
                if (f.status.value !== -1) {
                    return _.status === f.status.value;
                }

                return true;
            })
            .filter((_) => {
                if (f.type.value !== -1) {
                    return _.type_id === f.type.value;
                }

                return true;
            })
            .filter((_) => {
                if (filter.dateBegin) {
                    return moment(_.date).isSame(moment(filter.dateBegin).utc(true));
                }

                return true;
            })
            .sort((a, b) => {
                switch (filter.order.value) {
                    case TournamentOrder.DATE: {
                        if (moment(a.date).isSame(moment(b.date))) {
                            return 0;
                        }
                        return moment(a.date).isAfter(moment(b.date)) ? -1 : 1;
                    }
                    case TournamentOrder.POOL: {
                        return a.pool - b.pool;
                    }
                    case TournamentOrder.TYPE: {
                        return a.type_id - b.type_id;
                    }
                    case TournamentOrder.STATUS: {
                        return a.status - b.status;
                    }
                    case TournamentOrder.TITLE: {
                        if (a.title < b.title) {
                            return -1;
                        }
                        if (a.title > b.title) {
                            return 1;
                        }
                        return 0;
                    }
                    default: {
                        return 0;
                    }
                }
            });

        setTournaments(filteredTournaments);
    };

    const fetchTournaments = async () => {
        showLoader();
        setOriginalTournaments(null);
        const res = await request<ITournament[]>('/tournaments');
        setOriginalTournaments(res);
    };

    const fetchTournament = async (slug: string) => {
        showLoader();
        setTournament(null);
        const res = await request<ITournament>(`/tournaments/${slug}`);
        setTournament(res);
        setCurrentBracket(res.brackets?.length ? res.brackets[0] : null);
    };

    const updateFilter = (
        field: keyof ITournamentFilter,
        value: string | Date | PropsValue<{ value: number; label: string }> | null,
    ) => {
        setFilter((prev) => {
            const newVal = { ...prev };
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            newVal[field] = value;

            return newVal;
        });
    };

    const clearTournamentsData = () => {
        setOriginalTournaments(null);
        setTournaments(null);
        setTournament(null);
        setFilter(DEFAULT_FILTER_STATE);
        setCurrentBracket(null);
        setCurrentRoundId(null);
    };

    useEffect(() => {
        doFilterTournaments(filter, originalTournaments);
    }, [filter, originalTournaments]);

    useEffect(() => {
        if (!currentBracket) {
            setCurrentRoundId(null);

            return;
        }

        if (currentBracket.bracket_type === BracketType.TYPE_RELEGATION) {
            setCurrentRoundId('main-bracket');

            return;
        }

        if (
            currentBracket.bracket_type === BracketType.TYPE_GROUP ||
            currentBracket.bracket_type === BracketType.TYPE_SWISS
        ) {
            setCurrentRoundId(currentBracket.rounds?.length ? currentBracket.rounds[0].order.toString() : null);

            return;
        }
    }, [currentBracket]);

    return (
        <TournamentsContext.Provider
            value={{
                tournaments,
                tournament,
                currentBracket,
                setCurrentBracket,
                currentRoundId,
                setCurrentRoundId,
                fetchTournaments,
                fetchTournament,
                filter,
                updateFilter,
                clearTournamentsData,
            }}
        >
            {children}
        </TournamentsContext.Provider>
    );
};

export const useTournamentsContext = () => useContext(TournamentsContext);
