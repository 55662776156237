import React from 'react';
import { Link } from 'react-router-dom';

import { TOURNAMENT_PRIZE_IMAGES } from '~app/assets';
import bg5 from '~app/assets/img/bg5.jpg';
import { useRootContext, useTournamentsContext } from '~app/contexts';
import { Image } from '~app/pages/components/image';
import { IPlayer } from '~app/types/player.interface';

export const TournamentWinners = (): JSX.Element | null => {
    const { getAvatar } = useRootContext();
    const { tournament } = useTournamentsContext();

    if (
        !tournament?.winners?.standard.length &&
        !tournament?.winners?.special.length &&
        !tournament?.winners?.secondary.length
    ) {
        return null;
    }

    return (
        <section className="section section--main section--sm">
            <div className="section-bg">
                <div className="section-bg__img" style={{ backgroundImage: `url(${bg5})` }} />
            </div>
            <div className="section-inner">
                <div className="container--sm">
                    <div className="content">
                        {!!tournament?.winners.standard.length && (
                            <div className="content-block">
                                <h6 className="content-block__title">Standard Prizes</h6>
                                <div className="prizes">
                                    {tournament.winners.standard.map((winner, index) => {
                                        const playerAvatar = getAvatar((winner.player || {}) as IPlayer);

                                        return (
                                            <div className="prize" key={index}>
                                                <picture>
                                                    <source
                                                        srcSet={TOURNAMENT_PRIZE_IMAGES[index].webp}
                                                        type="image/webp"
                                                    />
                                                    <source srcSet={TOURNAMENT_PRIZE_IMAGES[index].img} />
                                                    <img
                                                        className="prize-img"
                                                        src={TOURNAMENT_PRIZE_IMAGES[index].img}
                                                        alt=""
                                                    />
                                                </picture>
                                                <div className="prize-inner">
                                                    <div className="prize-participant">
                                                        {tournament.team_mode === 0 && (
                                                            <div className="participant">
                                                                <div className="participant-avatar">
                                                                    <Image
                                                                        img={playerAvatar.img}
                                                                        webp={playerAvatar.webp}
                                                                        defaultImg={playerAvatar.defaultImg}
                                                                    />
                                                                </div>
                                                                <div className="participant-content">
                                                                    <div
                                                                        className="participant-title h5"
                                                                        style={{
                                                                            color: winner.player?.color || '#fff',
                                                                        }}
                                                                    >
                                                                        {!!winner.player?.external_link && (
                                                                            <Link
                                                                                to={winner.player.external_link}
                                                                                className="no-decor"
                                                                                target="_blank"
                                                                            >
                                                                                {winner.player.nick}
                                                                            </Link>
                                                                        )}
                                                                        {!winner.player?.external_link &&
                                                                            winner.player?.nick}
                                                                    </div>
                                                                    <div className="participant-prop">
                                                                        {winner.player?.class}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {tournament.team_mode === 1 && (
                                                            <div className="participant">
                                                                <div className="participant-content">
                                                                    <div className="participant-title h5">
                                                                        {winner.team?.name}
                                                                    </div>
                                                                    <div className="participant-prop prop">Team</div>
                                                                </div>
                                                                {winner.team?.players.map((player) => {
                                                                    const teamPlayerAvatar = getAvatar(
                                                                        player as IPlayer,
                                                                    );

                                                                    return (
                                                                        <>
                                                                            <div
                                                                                className="participant-avatar"
                                                                                style={{ marginLeft: '20px' }}
                                                                            >
                                                                                <Image
                                                                                    img={teamPlayerAvatar.img}
                                                                                    webp={teamPlayerAvatar.webp}
                                                                                    defaultImg={
                                                                                        teamPlayerAvatar.defaultImg
                                                                                    }
                                                                                />
                                                                            </div>
                                                                            <div className="participant-content">
                                                                                <div
                                                                                    className="participant-title h6"
                                                                                    style={{
                                                                                        color: player.color || '#fff',
                                                                                    }}
                                                                                >
                                                                                    {!!player.external_link && (
                                                                                        <Link
                                                                                            to={player.external_link}
                                                                                            className="no-decor"
                                                                                            target="_blank"
                                                                                        >
                                                                                            {player.nick}
                                                                                        </Link>
                                                                                    )}
                                                                                    {!player.external_link &&
                                                                                        player.nick}
                                                                                </div>
                                                                                <div className="participant-prop">
                                                                                    {player.class}
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    );
                                                                })}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="prize-content">
                                                        <div className="h3 prize-value">{winner.money}</div>
                                                        <div className="h6 prize-place">{winner.description}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}

                        {!!tournament?.winners.special.length && (
                            <div className="content-block">
                                <h6 className="content-block__title">special prizes</h6>
                                <div className="prizes">
                                    {tournament.winners.special.map((winner, index) => {
                                        const playerAvatar = getAvatar((winner.player || {}) as IPlayer);

                                        return (
                                            <div className="prize" key={index}>
                                                <picture>
                                                    <source
                                                        srcSet={TOURNAMENT_PRIZE_IMAGES[(index % 14) + 4].webp}
                                                        type="image/webp"
                                                    />
                                                    <source srcSet={TOURNAMENT_PRIZE_IMAGES[(index % 14) + 4].img} />
                                                    <img
                                                        className="prize-img"
                                                        src={TOURNAMENT_PRIZE_IMAGES[(index % 14) + 4].img}
                                                        alt=""
                                                    />
                                                </picture>
                                                <div className="prize-inner">
                                                    <div className="prize-participant">
                                                        {tournament.team_mode === 0 && (
                                                            <div className="participant">
                                                                <div className="participant-avatar">
                                                                    <Image
                                                                        img={playerAvatar.img}
                                                                        webp={playerAvatar.webp}
                                                                        defaultImg={playerAvatar.defaultImg}
                                                                    />
                                                                </div>
                                                                <div className="participant-content">
                                                                    <div
                                                                        className="participant-title h5"
                                                                        style={{
                                                                            color: winner.player?.color || '#fff',
                                                                        }}
                                                                    >
                                                                        {!!winner.player?.external_link && (
                                                                            <Link
                                                                                to={winner.player.external_link}
                                                                                className="no-decor"
                                                                                target="_blank"
                                                                            >
                                                                                {winner.player.nick}
                                                                            </Link>
                                                                        )}
                                                                        {!winner.player?.external_link &&
                                                                            winner.player?.nick}
                                                                    </div>
                                                                    <div className="participant-prop">
                                                                        {winner.player?.class}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {tournament.team_mode === 1 && (
                                                            <div className="participant">
                                                                <div className="participant-content">
                                                                    <div className="participant-title h5">
                                                                        {winner.team?.name}
                                                                    </div>
                                                                    <div className="participant-prop prop">Team</div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="prize-content">
                                                        <div className="h3 prize-value">{winner.money}</div>
                                                        <div className="h6 prize-place">{winner.description}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}

                        {!!tournament?.winners.secondary.length && (
                            <div className="content-block">
                                <h6 className="content-block__title">secondary prizes</h6>
                                <div className="prizes">
                                    {tournament.winners.secondary.map((winner, index) => {
                                        const playerAvatar = getAvatar((winner.player || {}) as IPlayer);

                                        return (
                                            <div className="prize" key={index}>
                                                <div className="prize-inner">
                                                    <div className="prize-participant">
                                                        {tournament.team_mode === 0 && (
                                                            <div className="participant">
                                                                <div className="participant-avatar">
                                                                    <Image
                                                                        img={playerAvatar.img}
                                                                        webp={playerAvatar.webp}
                                                                        defaultImg={playerAvatar.defaultImg}
                                                                    />
                                                                </div>
                                                                <div className="participant-content">
                                                                    <div
                                                                        className="participant-title h5"
                                                                        style={{
                                                                            color: winner.player?.color || '#fff',
                                                                        }}
                                                                    >
                                                                        {!!winner.player?.external_link && (
                                                                            <Link
                                                                                to={winner.player.external_link}
                                                                                className="no-decor"
                                                                                target="_blank"
                                                                            >
                                                                                {winner.player.nick}
                                                                            </Link>
                                                                        )}
                                                                        {!winner.player?.external_link &&
                                                                            winner.player?.nick}
                                                                    </div>
                                                                    <div className="participant-prop">
                                                                        {winner.player?.class}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {tournament.team_mode === 1 && (
                                                            <div className="participant">
                                                                <div className="participant-content">
                                                                    <div className="participant-title h5">
                                                                        {winner.team?.name}
                                                                    </div>
                                                                    <div className="participant-prop prop">Team</div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="prize-content">
                                                        <div className="h3 prize-value">{winner.money}</div>
                                                        <div className="h6 prize-place">{winner.description}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};
