import React from 'react';
import SimpleBar from 'simplebar-react';

import { useTournamentsContext } from '~app/contexts';
import { BracketType } from '~app/types/bracket.interface';

export const TournamentBracketMenu = (): JSX.Element | null => {
    const { currentBracket, currentRoundId, setCurrentRoundId } = useTournamentsContext();

    return (
        <SimpleBar className="nav nav--sm">
            <div className="container--sm">
                <div className="nav-container">
                    <ul className="nav-list" id="group-or-swiss-menu">
                        {currentBracket?.bracket_type === BracketType.TYPE_RELEGATION &&
                            !!currentBracket.roundsMain?.rounds.length && (
                                <li className="nav-item">
                                    <a
                                        className={`nav-link js-tab-btn ${
                                            currentRoundId === 'main-bracket' ? 'active' : ''
                                        }`}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => setCurrentRoundId('main-bracket')}
                                    >
                                        {currentBracket.roundsMain.title}
                                    </a>
                                </li>
                            )}

                        {currentBracket?.bracket_type === BracketType.TYPE_RELEGATION &&
                            !!currentBracket.second_defeat &&
                            !!currentBracket.roundsDefeat?.rounds.length && (
                                <li className="nav-item">
                                    <a
                                        className={`nav-link js-tab-btn ${
                                            currentRoundId === 'defeat-bracket' ? 'active' : ''
                                        }`}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => setCurrentRoundId('defeat-bracket')}
                                    >
                                        {currentBracket.roundsDefeat.title}
                                    </a>
                                </li>
                            )}

                        {currentBracket?.bracket_type === BracketType.TYPE_RELEGATION &&
                            !!currentBracket.roundsGrand?.rounds.length && (
                                <li className="nav-item">
                                    <a
                                        className={`nav-link js-tab-btn ${
                                            currentRoundId === 'grand-bracket' ? 'active' : ''
                                        }`}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => setCurrentRoundId('grand-bracket')}
                                    >
                                        {currentBracket.roundsGrand.title}
                                    </a>
                                </li>
                            )}

                        {(currentBracket?.bracket_type === BracketType.TYPE_GROUP ||
                            currentBracket?.bracket_type === BracketType.TYPE_SWISS) &&
                            currentBracket.rounds?.map((round) => (
                                <li className="nav-item" key={round.order}>
                                    <a
                                        className={`nav-link js-tab-btn ${
                                            currentRoundId === round.order.toString() ? 'active' : ''
                                        }`}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => setCurrentRoundId(round.order.toString())}
                                    >
                                        {round.title}
                                    </a>
                                </li>
                            ))}

                        {(currentBracket?.bracket_type === BracketType.TYPE_GROUP ||
                            currentBracket?.bracket_type === BracketType.TYPE_SWISS) &&
                            currentBracket.standings?.length && (
                                <li className="nav-item">
                                    <a
                                        className={`nav-link js-tab-btn ${
                                            currentRoundId === 'standings' ? 'active' : ''
                                        }`}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => setCurrentRoundId('standings')}
                                    >
                                        standings
                                    </a>
                                </li>
                            )}
                    </ul>
                </div>
            </div>
        </SimpleBar>
    );
};
