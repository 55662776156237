import React from 'react';

import preloader from '~app/assets/img/preloader.gif';
import { useRootContext } from '~app/contexts';

export const Loader = (): JSX.Element | null => {
    const { isLoaderVisible } = useRootContext();

    return (
        <div className="loader" style={{ display: isLoaderVisible ? 'flex' : 'none' }}>
            <div className="loader-inner">
                <img className="loader-img" src={preloader} alt="" />
            </div>
        </div>
    );
};
