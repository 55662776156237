import React from 'react';

import { useRootContext } from '~app/contexts';
import { Image } from '~app/pages/components/image';
import { IParticipant } from '~app/types/participant.interface';

interface ITournamentParticipantProps {
    participant: IParticipant;
}
export const TournamentParticipant = ({ participant }: ITournamentParticipantProps): JSX.Element => {
    const { getAvatar } = useRootContext();

    const avatar = getAvatar(participant);

    return (
        <div className="participant">
            <div className="participant-avatar">
                <Image img={avatar.img} webp={avatar.webp} defaultImg={avatar.defaultImg} />
            </div>
            <div className="participant-content">
                <div className="participant-title h6" style={{ color: participant.color }}>
                    {participant.external_link && (
                        <a href={participant.external_link} className="no-decor" target="_blank" rel="noreferrer">
                            {participant.nick}
                        </a>
                    )}
                    {!participant.external_link && participant.nick}
                </div>
                <div className="participant-prop">{participant.class_name}</div>
            </div>
        </div>
    );
};
