export { request } from './request';

export const makeS3ResourceLink = (_filePath?: string): string | undefined => {
    if (!_filePath) {
        return undefined;
    }

    const filePath = _filePath.startsWith('/') ? _filePath.slice(1) : _filePath;

    return `https://idleague-storage-${process.env.REACT_APP_ENV}.s3.amazonaws.com/${filePath}`;
};

export const primers: { [key: string]: any } = {
    player: (a: string) => a.toUpperCase(),
    team: (a: string) => a.toUpperCase(),
    nick: (a: string) => a.toUpperCase(),
    class_name: (a: string) => a.toUpperCase(),
    world: (a: string) => a?.toUpperCase(),
    faction: (a: string) => a.toUpperCase(),
    status: (a: string) => a.toUpperCase(),
    top: parseInt,
    win: parseInt,
    tie: parseInt,
    lose: parseInt,
    points: parseInt,
};

export const sort_by = function (field: string, reverse: boolean, primer: any) {
    const key = primer
        ? function (x: any) {
              return primer(x[field]);
          }
        : function (x: any) {
              return x[field];
          };

    return function (a: any, b: any) {
        const _a = key(a);
        const _b = key(b);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return (!reverse ? 1 : -1) * ((_a > _b) - (_b > _a));
    };
};

export const nl2br = (str: string, is_xhtml = false) => {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }

    const breakTag = is_xhtml || typeof is_xhtml === 'undefined' ? '<br />' : '<br>';

    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
};
