import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { isArray } from 'lodash';
import { stringify } from 'qs';

export interface HttpError {
    error?: string;
    message?: string | string[];
}

export interface CustomRequestConfig {
    noRedirectOnErrorResponse?: boolean;
}

export function parseJSON<T>(response: AxiosResponse<T>) {
    return response.data;
}

export async function checkStatus<T extends HttpError>(response: AxiosResponse<T>) {
    if (response && response.status && response.status >= 200 && response.status < 300) {
        return response;
    }

    const errorData = response.data instanceof Blob ? JSON.parse(await response.data.text()) : response.data;

    if (response.status === 401) {
        throw response;
    }

    if (isArray(errorData.message)) {
        throw errorData.message.join(', ');
    }

    throw errorData;
}

const BASE_URLS: { [key: string]: string } = {
    prod: 'https://tbqkzosirl.execute-api.us-east-1.amazonaws.com/prod/api',
    dev: 'https://s6q9s1t1o4.execute-api.us-east-1.amazonaws.com/dev/api',
    default: '/api',
};

export async function request<T>(url: string, config?: AxiosRequestConfig & CustomRequestConfig): Promise<T> {
    const baseURL = BASE_URLS[process.env.REACT_APP_ENV ?? 'default'] ?? BASE_URLS.default;

    return axios(url, {
        ...config,
        baseURL,
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
            ...config?.headers,
        },
        onUploadProgress: config?.onUploadProgress,
        paramsSerializer: (params) => {
            return stringify(params, { arrayFormat: 'brackets' });
        },
    })
        .then(async (data) => {
            const response = await checkStatus(data);

            return parseJSON(response) as T;
        })
        .catch(async (data) => {
            const res = data.response;

            // if (
            //     !config?.noRedirectOnErrorResponse &&
            //     [404, 500].includes(res.status) &&
            //     (!config?.method || config?.method === 'GET')
            // ) {
            //     window.history.pushState({}, '', '/error?code=' + res.status);
            //     const navEvent = new PopStateEvent('popstate');
            //     window.dispatchEvent(navEvent);
            // }

            const response: any = await checkStatus(res);

            return parseJSON(response);
        });
}
