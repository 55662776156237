import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import SimpleBar from 'simplebar-react';

import MainSymbols from '~app/assets/sprites/main.symbol.svg';
import { useRootContext } from '~app/contexts';
import { ITournament, TOURNAMENT_STATUS_DICT } from '~app/types/tournament.interface';
import { makeS3ResourceLink } from '~app/utils';

interface ITournamentCardProps {
    tournament: ITournament;
}

export const TournamentCard = ({ tournament }: ITournamentCardProps): JSX.Element => {
    const { isAdmin, getTournamentTypeById, showLoader } = useRootContext();

    return (
        <Link
            className={`tourney ${isAdmin ? 'tourney--admin' : ''}`}
            to={`/tournaments/${tournament.slug}`}
            onClick={showLoader}
        >
            <div className="tourney-inner">
                <div className="tourney-media">
                    <picture>
                        <source srcSet={makeS3ResourceLink(tournament.bg_image)} />
                        <img className="tourney-img" src={makeS3ResourceLink(tournament.bg_image)} alt="" />
                    </picture>
                </div>
                <div className="tourney-content">
                    <SimpleBar className="tourney-top">
                        <div className="tourney-top__main">
                            <div className="tourney-top__inner">
                                <div className="tourney-status">
                                    <div
                                        className={`tag tag--${TOURNAMENT_STATUS_DICT.get(tournament.status)
                                            ?.toLowerCase()
                                            .replace('in ', '')}`}
                                    >
                                        {TOURNAMENT_STATUS_DICT.get(tournament.status)}
                                    </div>
                                </div>
                                {isAdmin && (
                                    <div className="tourney-controls">
                                        <div className="tourney-controls__group">
                                            {/*        <?php if (Yii::$app->user->can('updateTournament', ['tournamentId' => $model->id])) : ?>*/}
                                            {/*        <button className="btn btn--sm js-ajax-popup" type="button" data-pjax="0"*/}
                                            {/*                data-url="<?= Url::to(['tournament/update', 'id' => $model->id]) ?>">*/}
                                            {/*            edit*/}
                                            {/*        </button>*/}
                                            {/*        <?php endif; ?>*/}

                                            {/*        <?php if (Yii::$app->user->can('updateTournament', ['tournamentId' => $model->id])) : ?>*/}
                                            {/*        <button className="btn btn--sm js-ajax-popup" type="button" data-pjax="0"*/}
                                            {/*                data-url="<?= Url::to(['tournament/delete', 'id' => $model->id]) ?>">*/}
                                            {/*            delete*/}
                                            {/*        </button>*/}
                                            {/*        <?php endif; ?>*/}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </SimpleBar>
                    <div className="tourney-main">
                        {tournament.pool_custom && (
                            <div className="tourney-prize">
                                <div className="tag">
                                    prize pool <span>{tournament.pool_custom}</span>
                                </div>
                            </div>
                        )}
                        <div className="tourney-title h5" dangerouslySetInnerHTML={{ __html: tournament.title }} />
                        <div className="tourney-info">
                            <div className="tourney-type">
                                <div className="info">
                                    <div className="info-icon">
                                        <svg className="icon">
                                            <use href={`${MainSymbols}#image-shield`}></use>
                                        </svg>
                                    </div>
                                    <div className="icon-content">
                                        <div
                                            className="info-value h6"
                                            dangerouslySetInnerHTML={{
                                                __html: getTournamentTypeById(tournament.type_id)?.name ?? '',
                                            }}
                                        />
                                        <div className="info-prop prop">type</div>
                                    </div>
                                </div>
                            </div>
                            <div className="tourney-date">
                                <div className="date">
                                    <div className="date-title h6">{moment(tournament.date).format('D MMMM YYYY')}</div>
                                    <div className="date-prop prop">date</div>
                                    <div className="date-icon">
                                        <svg className="icon">
                                            <use href={`${MainSymbols}#image-chevron`}></use>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
};
