import React, { createContext, useContext, useState } from 'react';

import { useRootContext } from '~app/contexts/RootContext';
import { IStatistics } from '~app/types/statistics.interface';
import { request } from '~app/utils';

interface MainContextProps {
    statistics: IStatistics | null;
    fetchStatistics: () => Promise<void>;
    clearMainData: () => void;
}

export const MainContext = createContext<MainContextProps>({} as MainContextProps);

export const MainContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const { showLoader } = useRootContext();

    const [statistics, setStatistics] = useState<IStatistics | null>(null);

    const fetchStatistics = async () => {
        showLoader();
        setStatistics(null);
        const statisticsResp = await request<IStatistics>('/statistics');
        setStatistics(statisticsResp);
    };

    const clearMainData = () => {
        setStatistics(null);
    };

    return (
        <MainContext.Provider
            value={{
                statistics,
                fetchStatistics,
                clearMainData,
            }}
        >
            {children}
        </MainContext.Provider>
    );
};

export const useMainContext = () => useContext(MainContext);
