import moment from 'moment/moment';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

import { useRootContext } from '~app/contexts';
import { Image } from '~app/pages/components/image';
import { IChampion } from '~app/types/champion.interface';
import { makeS3ResourceLink } from '~app/utils';

interface IChampionCardProps {
    champion: IChampion;
}

export const ChampionCard = ({ champion }: IChampionCardProps): JSX.Element => {
    const { getAvatar, getTournamentTypeById, showLoader } = useRootContext();

    const getParticipantName = useCallback(() => {
        if (champion.player) {
            return champion.player.nick || 'Nick';
        }

        if (champion.team) {
            return champion.team.name || 'Name';
        }

        return '';
    }, [champion]);

    const avatar = champion.player ? getAvatar(champion.player) : null;

    return (
        <div className="champs-item" data-pjax="0">
            <Link className="champ" to={`/tournaments/${champion.tournament.slug}/participants`} onClick={showLoader}>
                <div className="champ-participant">
                    <div className="participant">
                        {!!avatar && (
                            <div className="participant-avatar">
                                <Image img={avatar.img} webp={avatar.webp} defaultImg={avatar.defaultImg} />
                            </div>
                        )}
                        <div className="participant-content">
                            <div className="participant-title h5" style={{ color: champion.player?.color || '#fff' }}>
                                {getParticipantName()}
                            </div>
                            <div className={`participant-prop ${champion.team ? 'prop' : ''}`}>
                                {champion.player?.class}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="champ-main">
                    <div className="champ-media">
                        <picture>
                            <source srcSet={makeS3ResourceLink(champion.tournament.bg_image)} />
                            <img className="champ-img" src={makeS3ResourceLink(champion.tournament.bg_image)} alt="" />
                        </picture>
                    </div>
                    <div className="champ-content">
                        <div className="champ-info">
                            <div className="champ-title h6">{champion.tournament.title}</div>
                            <div className="prop">
                                {champion.tournament.type_id
                                    ? getTournamentTypeById(champion.tournament.type_id)?.name
                                    : ''}
                            </div>
                            <div className="prop">{moment(champion.tournament.date).format('D MMMM YYYY')}</div>
                        </div>
                        <div className="champ-prize h6">
                            {champion.tournament.prize_one || champion.tournament.pool_custom}
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
};
