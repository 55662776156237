import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { PrivacyPolicyPage } from '~app/pages/privacy-policy.page';
import { TermsOfServicesPage } from '~app/pages/terms-of-services.page';

import { useRootContext } from '../contexts';
import { Loader } from '../modules/layout/loader/loader';
import { Layout } from '../modules/layout/templates/layout';
import { ChampionsPage } from './champions.page';
import { ContactsPage } from './contacts.page';
import { DonatePage } from './donate.page';
import { MainPage } from './main.page';
import { MediaPage } from './media.page';
import { MediaListPage } from './media-list.page';
import { ThanksPage } from './thanks.page';
import { TournamentPage } from './tournament.page';
import { TournamentsPage } from './tournaments.page';

const Public: React.FC = () => {
    const { hasToken } = useRootContext();

    if (hasToken) {
        return <Navigate replace to="/" />;
    }

    return <Outlet />;
};

const Private: React.FC = () => {
    return <Outlet />;
};

export const Pages = (): JSX.Element => {
    return (
        <>
            <Loader />
            <Routes>
                <Route path="/" element={<Public />}>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<MainPage />} />
                        <Route path="/champions" element={<ChampionsPage />} />
                        <Route path="/contacts" element={<ContactsPage />} />
                        <Route path="/donate" element={<DonatePage />} />
                        <Route path="/media" element={<MediaListPage />} />
                        <Route path="/media/:slug" element={<MediaPage />} />
                        <Route path="/thanks" element={<ThanksPage />} />
                        <Route path="/terms-of-services" element={<TermsOfServicesPage />} />
                        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                        <Route path="/tournaments" element={<TournamentsPage />} />
                        <Route path="/tournaments/:slug" element={<TournamentPage />} />
                        <Route path="/tournaments/:slug/:tournament_tab" element={<TournamentPage />} />
                    </Route>
                </Route>

                <Route path="/" element={<Private />}>
                    <Route path="/" element={<Layout />}></Route>
                </Route>
            </Routes>
        </>
    );
};
