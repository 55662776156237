export enum TournamentMediaType {
    UNKNOWN,
    YOUTUBE,
    TWITCH,
    TWITCH_STREAM,
}

export const getTournamentMediaData = (
    tournamentMedia: ITournamentMedia,
): { type: TournamentMediaType; videoId: string } => {
    const youtubeRegExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const youtubeMatch = tournamentMedia.content.match(youtubeRegExp);
    if (youtubeMatch && youtubeMatch[7].length == 11) {
        return { type: TournamentMediaType.YOUTUBE, videoId: youtubeMatch[7] };
    }

    const twitchRegExp = /(clips\.)?twitch\.tv\/(?:(?:videos\/(\d+))|(\w+(?:-[\w\d-]+)?)(?:\/clip\/(\w+))?)/;
    const twitchMatch = tournamentMedia.content.match(twitchRegExp);

    if (twitchMatch && twitchMatch[0]) {
        const text = twitchMatch[0].split('/');

        if (text[1] == 'videos') {
            return { type: TournamentMediaType.TWITCH, videoId: text[2] };
        } else if (text[2] == 'clip') {
            return { type: TournamentMediaType.TWITCH, videoId: text[3] };
        } else if (text[1]) {
            return { type: TournamentMediaType.TWITCH_STREAM, videoId: text[1] };
        }
    }

    return { type: TournamentMediaType.UNKNOWN, videoId: '' };
};

export interface ITournamentMedia {
    content: string;
    id: number;
    tournament_id: number;
}
