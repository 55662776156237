import prize1 from '~app/assets/img/prize1.jpg';
import prize1WebP from '~app/assets/img/prize1.webp';
import prize2 from '~app/assets/img/prize2.jpg';
import prize2WebP from '~app/assets/img/prize2.webp';
import prize3 from '~app/assets/img/prize3.jpg';
import prize3WebP from '~app/assets/img/prize3.webp';
import prize4 from '~app/assets/img/prize4.jpg';
import prize4WebP from '~app/assets/img/prize4.webp';
import prize5 from '~app/assets/img/prize5.jpg';
import prize5WebP from '~app/assets/img/prize5.webp';
import prize6 from '~app/assets/img/prize6.jpg';
import prize6WebP from '~app/assets/img/prize6.webp';
import prize7 from '~app/assets/img/prize7.jpg';
import prize7WebP from '~app/assets/img/prize7.webp';
import prize8 from '~app/assets/img/prize8.jpg';
import prize8WebP from '~app/assets/img/prize8.webp';
import prize9 from '~app/assets/img/prize9.jpg';
import prize9WebP from '~app/assets/img/prize9.webp';
import prize10 from '~app/assets/img/prize10.jpg';
import prize10WebP from '~app/assets/img/prize10.webp';
import prize11 from '~app/assets/img/prize11.jpg';
import prize11WebP from '~app/assets/img/prize11.webp';
import prize12 from '~app/assets/img/prize12.jpg';
import prize12WebP from '~app/assets/img/prize12.webp';
import prize13 from '~app/assets/img/prize13.jpg';
import prize13WebP from '~app/assets/img/prize13.webp';
import prize14 from '~app/assets/img/prize14.jpg';
import prize14WebP from '~app/assets/img/prize14.webp';
import prize15 from '~app/assets/img/prize15.jpg';
import prize15WebP from '~app/assets/img/prize15.webp';
import prize16 from '~app/assets/img/prize16.jpg';
import prize16WebP from '~app/assets/img/prize16.webp';
import prize17 from '~app/assets/img/prize17.jpg';
import prize17WebP from '~app/assets/img/prize17.webp';
import prize18 from '~app/assets/img/prize18.jpg';
import prize18WebP from '~app/assets/img/prize18.webp';

export const TOURNAMENT_PRIZE_IMAGES = [
    { img: prize1, webp: prize1WebP },
    { img: prize2, webp: prize2WebP },
    { img: prize3, webp: prize3WebP },
    { img: prize4, webp: prize4WebP },
    { img: prize5, webp: prize5WebP },
    { img: prize6, webp: prize6WebP },
    { img: prize7, webp: prize7WebP },
    { img: prize8, webp: prize8WebP },
    { img: prize9, webp: prize9WebP },
    { img: prize10, webp: prize10WebP },
    { img: prize11, webp: prize11WebP },
    { img: prize12, webp: prize12WebP },
    { img: prize13, webp: prize13WebP },
    { img: prize14, webp: prize14WebP },
    { img: prize15, webp: prize15WebP },
    { img: prize16, webp: prize16WebP },
    { img: prize17, webp: prize17WebP },
    { img: prize18, webp: prize18WebP },
];
