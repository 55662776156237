import React from 'react';

import MainSymbols from '~app/assets/sprites/main.symbol.svg';

export const Share = (): JSX.Element => {
    return (
        <div className="share">
            <div className="share-title h6">Share this event</div>
            <ul className="share-list">
                <li className="share-item">
                    <a
                        target="_blank"
                        rel="nofollow noreferrer"
                        className="share-link js-target-window"
                        href="https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Flocalhost%3A9020%2Ftournaments%2F1234%2Fparticipants"
                    >
                        facebook
                    </a>
                </li>
                <li className="share-item">
                    <a
                        target="_blank"
                        rel="nofollow noreferrer"
                        className="share-link js-target-window"
                        href="http://vk.com/share.php?url=http%3A%2F%2Flocalhost%3A9020%2Ftournaments%2F1234%2Fparticipants"
                    >
                        vk
                    </a>
                </li>
                <li className="share-item">
                    <a
                        target="_blank"
                        rel="nofollow noreferrer"
                        className="share-link js-target-window"
                        href="http://twitter.com/share?url=http%3A%2F%2Flocalhost%3A9020%2Ftournaments%2F1234%2Fparticipants"
                    >
                        twitter
                    </a>
                </li>
            </ul>
            <div className="share-drop">
                <div className="dropdown dropdown--sm">
                    <div className="dropdown-result js-dropdown-btn">
                        <div className="dropdown-result__text">more</div>
                        <div className="dropdown-result__icon">
                            <svg className="icon">
                                <use href={`${MainSymbols}#image-chevron`}></use>
                            </svg>
                        </div>
                    </div>
                    <div className="dropdown-box bottom">
                        <div className="close js-close">
                            <div className="close-inner">
                                <div className="close-icon">
                                    <svg className="icon">
                                        <use href={`${MainSymbols}#image-chevron`}></use>
                                    </svg>
                                </div>
                                <div className="close-text">close</div>
                            </div>
                        </div>
                        <div className="dropdown-box__inner">
                            <ul className="dropdown-items">
                                <li className="dropdown-item">
                                    <a
                                        target="_blank"
                                        rel="nofollow noreferrer"
                                        className="dropdown-link share-link js-target-window"
                                        href="tg://msg?text=http%3A%2F%2Flocalhost%3A9020%2Ftournaments%2F1234%2Fparticipants"
                                    >
                                        telegram
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
