import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import bg5 from '~app/assets/img/bg5.jpg';
import bg8 from '~app/assets/img/bg8.jpg';
import { useRootContext } from '~app/contexts';

export const DonatePage = (): JSX.Element => {
    const { settingsDict, hideLoader } = useRootContext();

    useEffect(() => {
        if (settingsDict !== null) {
            setTimeout(hideLoader, 500);
        }
    }, [settingsDict]);

    return (
        <>
            <section className="section section--head">
                <div className="section-bg">
                    <div className="section-bg__overlay">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <div className="section-bg__img" style={{ backgroundImage: `url(${bg8})` }}></div>
                </div>
                <div className="section-inner">
                    <div className="container">
                        <div className="section-title">
                            <h1 className="h2">Donate</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section section--main section--sm">
                <div className="section-bg">
                    <div className="section-bg__img" style={{ backgroundImage: `url(${bg5})` }}></div>
                </div>
                <div className="section-inner">
                    <div className="container--sm">
                        {settingsDict !== null && (
                            <div className="contacts">
                                <div className="mb" dangerouslySetInnerHTML={{ __html: settingsDict.donate_content }} />
                                <div className="contacts-items">
                                    <div className="contacts-item">
                                        <Link
                                            className="contacts-link"
                                            target="_blank"
                                            rel="nofollow"
                                            to={settingsDict.donate_team}
                                        >
                                            <span className="h5 contacts-link__text">Team</span>
                                        </Link>
                                    </div>
                                    <div className="contacts-item">
                                        <Link
                                            className="contacts-link"
                                            target="_blank"
                                            rel="nofollow"
                                            to={settingsDict.donate_prizepool}
                                        >
                                            <span className="h5 contacts-link__text">Prize Pool</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    );
};
