import React, { useEffect } from 'react';

import bg5 from '~app/assets/img/bg5.jpg';
import bg7 from '~app/assets/img/bg7.jpg';
import { useRootContext } from '~app/contexts';

export const ThanksPage = (): JSX.Element => {
    const { settingsDict, hideLoader } = useRootContext();

    useEffect(() => {
        if (settingsDict !== null) {
            setTimeout(hideLoader, 500);
        }
    }, [settingsDict]);

    return (
        <>
            <section className="section section--head">
                <div className="section-bg">
                    <div className="section-bg__overlay">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <div className="section-bg__img" style={{ backgroundImage: `url(${bg7})` }}></div>
                </div>
                <div className="section-inner">
                    <div className="container">
                        <div className="section-title">
                            <h1 className="h2">thanks</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section section--main section--sm">
                <div className="section-bg">
                    <div className="section-bg__img" style={{ backgroundImage: `url(${bg5})` }}></div>
                </div>
                <div className="section-inner">
                    <div className="container--sm">
                        <div className="thanks">
                            <div className="mb">
                                We would like to express our gratitude and say a big thank you to all those who help us
                            </div>
                            <div className="thanks-items">
                                {settingsDict?.thanks_nicks?.split('\r\n').map((nick, index) => {
                                    return (
                                        <div className="thanks-item" key={index}>
                                            {nick}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
