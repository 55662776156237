import { IBracketRound } from '~app/types/bracket-round.interface';
import { IGroupStanding } from '~app/types/group-standing.interface';
import { IStanding } from '~app/types/standing.interface';

export enum BracketType {
    TYPE_TABLE,
    TYPE_RELEGATION,
    TYPE_GROUP,
    TYPE_SWISS,
}

export interface IBracket {
    best_of: number;
    bracket_type: BracketType;
    editable: number;
    editable_participants: number;
    editable_scores: number;
    group_count: number | null;
    id: number;
    order: number | null;
    participants: number;
    round_count: number;
    second_defeat: number;
    third_place: number;
    title: string;
    tournament_id: number;
    created_at: Date;
    updated_at: Date;
    rounds: IBracketRound[];
    standings: IStanding[] | IGroupStanding[];
    roundsMain?: {
        title: string;
        rounds: IBracketRound[];
    };
    roundsGrand?: {
        title: string;
        rounds: IBracketRound[];
    };
    roundsDefeat: {
        title: string;
        rounds: IBracketRound[];
    } | null;
    headers: string[];
    rows: {
        columns: { title: string; value: string }[];
        faction: string;
        faction_avatar: string;
        id: string;
        race: string;
        team: string | null;
        world: string;
        player_id: number;
        top: number;
    }[];
}
