export enum MediaListOrder {
    DATE,
    TITLE,
}

export enum MediaType {
    ALL,
    TEXT,
    VIDEO,
}

export const SELECT_MEDIA_LIST_ORDER_OPTIONS = [
    { value: MediaListOrder.DATE, label: 'Date' },
    { value: MediaListOrder.TITLE, label: 'Title' },
];

export const SELECT_MEDIA_LIST_TYPE_OPTIONS = [
    { value: MediaType.ALL, label: 'All' },
    { value: MediaType.TEXT, label: 'Text' },
    { value: MediaType.VIDEO, label: 'Video' },
];

export interface IMedia {
    id: number;
    active: number;
    bg_image: string;
    content: string;
    date: string;
    is_text: number;
    is_video: number;
    slug: string;
    title: string;
}
