import React, { createContext, useContext, useEffect, useState } from 'react';

import ChampAvatar1 from '~app/assets/img/champ-avatar1.jpg';
import ChampAvatar1Webp from '~app/assets/img/champ-avatar1.webp';
import { IParticipant } from '~app/types/participant.interface';
import { IPlayer } from '~app/types/player.interface';
import { ISetting, ISettingsResponse } from '~app/types/setting.interface';
import { IITournamentTypesResponse, ITournamentType } from '~app/types/tournament-type.interface';
import { makeS3ResourceLink, request } from '~app/utils';

interface RootContextProps {
    settings: ISetting[] | null;
    settingsDict: { [key: string]: string } | null;
    hasToken: boolean;
    isAdmin: boolean;
    isGuest: boolean;
    isLoaderVisible: boolean;
    logout: () => void;
    showLoader: () => void;
    hideLoader: () => void;
    tournamentTypes: ITournamentType[] | null;
    getTournamentTypeById: (id: number) => ITournamentType | null;
    getAvatar: (player: Partial<IPlayer> | Partial<IParticipant>) => { img: any; webp?: any; defaultImg: any };
}

export const RootContext = createContext<RootContextProps>({} as RootContextProps);

export const RootContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const [isLoaderVisible, setLoaderVisibility] = useState<boolean>(true);
    const [tournamentTypes, setTournamentTypes] = useState<ITournamentType[] | null>(null);
    const [settings, setSettings] = useState<ISetting[] | null>(null);
    const [settingsDict, setSettingsDict] = useState<{ [key: string]: string } | null>(null);
    const handleRemoveToken = () => {
        localStorage.removeItem('token');
    };

    const fetchSettings = async () => {
        const settingsResp = await request<ISettingsResponse>('/settings');
        if (!Array.isArray(settingsResp)) {
            console.error('Invalid response');
            return;
        }

        setSettings(settingsResp);

        const settingsDictResp = settingsResp.reduce((acc: { [key: string]: string }, item: ISetting) => {
            acc[item.key] = item.value;

            return acc;
        }, {});
        setSettingsDict(settingsDictResp);
    };

    const fetchTournamentTypes = async () => {
        const tournamentTypesResp = await request<IITournamentTypesResponse>('/tournament-types');
        setTournamentTypes(tournamentTypesResp);
    };

    const getTournamentTypeById = (id: number): ITournamentType | null => {
        if (tournamentTypes === null) {
            return null;
        }

        return tournamentTypes.find((_) => _.id === id) ?? null;
    };

    const showLoader = () => {
        setLoaderVisibility(true);
    };

    const hideLoader = () => {
        setLoaderVisibility(false);
    };

    useEffect(() => {
        fetchSettings().then();
        fetchTournamentTypes().then();
    }, []);

    const getAvatar = (player: Partial<IPlayer>) => {
        const defaultAvatar = { defaultImg: ChampAvatar1 };

        if (player.avatar) {
            return {
                ...defaultAvatar,
                img: makeS3ResourceLink(player.avatar),
            };
        }

        if (player.race_avatar) {
            return {
                ...defaultAvatar,
                img: makeS3ResourceLink(player.race_avatar),
            };
        }

        return { ...defaultAvatar, img: ChampAvatar1, webp: ChampAvatar1Webp };
    };

    return (
        <RootContext.Provider
            value={{
                settings,
                settingsDict,
                hasToken: false,
                isAdmin: false,
                isGuest: true,
                isLoaderVisible,
                logout: handleRemoveToken,
                showLoader,
                hideLoader,
                tournamentTypes,
                getTournamentTypeById,
                getAvatar,
            }}
        >
            {children}
        </RootContext.Provider>
    );
};

export const useRootContext = () => useContext(RootContext);
