import dragscroll from 'dragscroll';
import React, { useMemo } from 'react';
import SimpleBar from 'simplebar-react';

import { useTournamentsContext } from '~app/contexts';
import { useVisibility, VisibilityType } from '~app/utils/hooks/use-visibility.hook';

export const TournamentRelegationBracket = (): JSX.Element | null => {
    const { currentBracket, currentRoundId } = useTournamentsContext();

    const [isInViewportBracketsArea, bracketsAreaRef] = useVisibility<HTMLDivElement>(VisibilityType.HORIZONTAL, 10);

    const rounds = useMemo(() => {
        if (!currentBracket) {
            return [];
        }

        switch (currentRoundId) {
            case 'main-bracket': {
                return currentBracket.roundsMain?.rounds || [];
            }
            case 'defeat-bracket': {
                return currentBracket.roundsDefeat?.rounds || [];
            }
            case 'grand-bracket': {
                return currentBracket.roundsGrand?.rounds || [];
            }
            default: {
                return [];
            }
        }
    }, [currentBracket, currentRoundId]);

    if (!currentBracket || !currentRoundId) {
        return null;
    }

    return (
        <div className="filter-content">
            <div className="tabs fullscreen-content active">
                <div className="tabs-item">
                    <div
                        className={`brackets ${!isInViewportBracketsArea ? 'scroll' : ''} ${
                            rounds.length < 5 ? 'small' : ''
                        }`}
                    >
                        <SimpleBar
                            className="brackets-inner"
                            draggable
                            classNames={{ contentWrapper: 'dragscroll' }}
                            ref={() => {
                                dragscroll.reset();
                            }}
                        >
                            <div className="brackets-area">
                                <div className="brackets-row" ref={bracketsAreaRef} style={{ width: 'max-content' }}>
                                    {rounds.map((round) => {
                                        return (
                                            <div className="brackets-col" style={{ flex: 1 }} key={round.id}>
                                                <div className="brackets-title">
                                                    <div className="group-title prop">{round.title}</div>
                                                </div>
                                                <div className="brackets-round">
                                                    {round.duels.map((duel) => {
                                                        return (
                                                            <div className="brackets-game js-group-item" key={duel.id}>
                                                                <div className="brackets-game__title">{duel.code}</div>
                                                                <div className="brackets-game__items">
                                                                    <div
                                                                        className={`brackets-item js-group-result ${
                                                                            duel.player1.winner &&
                                                                            duel.player1.score !== null
                                                                                ? 'brackets-item--winner'
                                                                                : ''
                                                                        }`}
                                                                    >
                                                                        <div
                                                                            className="brackets-item__title"
                                                                            style={{ color: duel.player1.color }}
                                                                        >
                                                                            {duel.player1.external_link && (
                                                                                <a
                                                                                    href={duel.player1.external_link}
                                                                                    className="no-decor"
                                                                                    target="_blank"
                                                                                    rel="noreferrer"
                                                                                >
                                                                                    {duel.player1.name}
                                                                                </a>
                                                                            )}
                                                                            {!duel.player1.external_link &&
                                                                                duel.player1.name}
                                                                        </div>
                                                                        <div className="group-item__score">
                                                                            {duel.player1.score}
                                                                        </div>
                                                                    </div>

                                                                    <div
                                                                        className={`brackets-item js-group-result ${
                                                                            duel.player2.winner &&
                                                                            duel.player2.score !== null
                                                                                ? 'brackets-item--winner'
                                                                                : ''
                                                                        }`}
                                                                    >
                                                                        <div
                                                                            className="brackets-item__title"
                                                                            style={{ color: duel.player2.color }}
                                                                        >
                                                                            {duel.player2.external_link && (
                                                                                <a
                                                                                    href={duel.player2.external_link}
                                                                                    className="no-decor"
                                                                                    target="_blank"
                                                                                    rel="noreferrer"
                                                                                >
                                                                                    {duel.player2.name}
                                                                                </a>
                                                                            )}
                                                                            {!duel.player2.external_link &&
                                                                                duel.player2.name}
                                                                        </div>
                                                                        <div className="group-item__score">
                                                                            {duel.player2.score}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                                <div className="brackets-title">
                                                    <div className="group-title prop">{round.title}</div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </SimpleBar>
                    </div>
                </div>
            </div>
        </div>
    );
};
