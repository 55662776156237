/* eslint-disable no-template-curly-in-string */

export default {
    translations: {
        common: {
            back: 'Назад',
        },
        alerts: {
            success: {},
            error: {},
            warnings: {},
        },
        errors: {
            anyErr: 'Произошла ошибка. {{logCode}}',
            something: 'Сервер недоступен, попробуйте позже. {{logCode}}',
        },
    },
};
