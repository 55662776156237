import React from 'react';

import { useTournamentsContext } from '~app/contexts';
import { Empty } from '~app/pages/components/empty';
import { nl2br } from '~app/utils';

export const TournamentRules = (): JSX.Element => {
    const { tournament } = useTournamentsContext();

    return (
        <section className="section section--main section--sm">
            <div className="section-bg">
                <div className="section-bg__img" style={{ backgroundImage: `url(bg5)` }}></div>
            </div>
            <div className="section-inner">
                <div className="container--sm">
                    {!!tournament?.rules?.length && (
                        <div className="rules">
                            {tournament.rules.map((rule) => {
                                return (
                                    <div className="rules-item" key={rule.id}>
                                        <div className="rules-item__title h6">{rule.title}</div>
                                        <div className="rules-item__text">
                                            <p dangerouslySetInnerHTML={{ __html: nl2br(rule.description) }} />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    {!tournament?.rules?.length && <Empty />}
                </div>
            </div>
        </section>
    );
};
