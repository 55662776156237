import React, { useRef } from 'react';

interface IImageProps {
    img: any;
    webp: any;
    defaultImg: any;
}

export const Image = ({ img, webp, defaultImg }: IImageProps) => {
    const imgRef = useRef<HTMLImageElement>(null);
    const sourceImgRef = useRef<HTMLSourceElement>(null);

    const onImgError = () => {
        if (sourceImgRef.current && defaultImg) {
            sourceImgRef.current.srcset = defaultImg;
        }

        if (imgRef.current && defaultImg) {
            imgRef.current.src = defaultImg;
            imgRef.current.onerror = null;
        }
    };

    return (
        <picture>
            {!!webp && <source srcSet={webp} type="image/webp" />}
            {!!img && (
                <>
                    <source ref={sourceImgRef} srcSet={img} />
                    <img ref={imgRef} src={img} alt="" onError={onImgError} />
                </>
            )}
        </picture>
    );
};
