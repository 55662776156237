import React, { useMemo } from 'react';
import SimpleBar from 'simplebar-react';

import bg5 from '~app/assets/img/bg5.jpg';
import { useTournamentsContext } from '~app/contexts';
import { TournamentBracketMenu } from '~app/pages/components/tournament-bracket-menu';
import { TournamentBracketStandings } from '~app/pages/components/tournament-bracket-standings';
import { TournamentGroupBracket } from '~app/pages/components/tournament-group-bracket';
import { TournamentRelegationBracket } from '~app/pages/components/tournament-relegation-bracket';
import { TournamentSwissBracket } from '~app/pages/components/tournament-swiss-bracket';
import { TournamentTableBracket } from '~app/pages/components/tournament-table-bracket';
import { BracketType } from '~app/types/bracket.interface';

export const TournamentBrackets = (): JSX.Element | null => {
    const { tournament, currentBracket, setCurrentBracket, currentRoundId } = useTournamentsContext();

    const isShowBracketMenu = useMemo(() => {
        const allowedTypes = [BracketType.TYPE_RELEGATION, BracketType.TYPE_GROUP, BracketType.TYPE_SWISS];

        return !!currentBracket?.bracket_type && allowedTypes.includes(currentBracket.bracket_type);
    }, [currentBracket]);

    if (tournament === null) {
        return null;
    }

    return (
        <>
            <SimpleBar className="nav nav--sm">
                <div className="container--sm">
                    <div className="nav-container">
                        <ul className="nav-list">
                            {tournament.brackets?.map((bracket) => (
                                <li className="nav-item" key={bracket.id}>
                                    <a
                                        className={`nav-link ${bracket.id === currentBracket?.id ? 'active' : ''}`}
                                        onClick={() => setCurrentBracket(bracket)}
                                    >
                                        {bracket.title}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </SimpleBar>

            {isShowBracketMenu && <TournamentBracketMenu />}

            {currentRoundId !== 'standings' && (
                <section className="section section--main section--sm">
                    <div className="section-bg">
                        <div className="section-bg__img" style={{ backgroundImage: `url(${bg5})` }} />
                    </div>
                    <div className="section-inner">
                        {currentBracket?.bracket_type === BracketType.TYPE_TABLE && <TournamentTableBracket />}
                        {currentBracket?.bracket_type === BracketType.TYPE_RELEGATION && (
                            <TournamentRelegationBracket />
                        )}
                        {currentBracket?.bracket_type === BracketType.TYPE_GROUP && <TournamentGroupBracket />}
                        {currentBracket?.bracket_type === BracketType.TYPE_SWISS && <TournamentSwissBracket />}
                    </div>
                </section>
            )}

            {currentRoundId === 'standings' && <TournamentBracketStandings />}
        </>
    );
};
