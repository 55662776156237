import dragscroll from 'dragscroll';
import moment, { Moment } from 'moment/moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import SimpleBarCore from 'simplebar-core';
import SimpleBar from 'simplebar-react';

import MainSymbols from '~app/assets/sprites/main.symbol.svg';
import { useRootContext, useTournamentsContext } from '~app/contexts';

export const TournamentsSchedule = (): JSX.Element => {
    const { getTournamentTypeById } = useRootContext();
    const { tournaments } = useTournamentsContext();

    const [isCheckedPrimary, setCheckedPrimary] = useState<boolean>(true);
    const [isCheckedNonPrimary, setCheckedNonPrimary] = useState<boolean>(true);
    const [chartDayWidth, setChartDayWidth] = useState<number>(0);
    const [chartSidebarItemHeight, setChartSidebarItemHeight] = useState<number[]>([]);
    const [isScheduleFullScreen, setScheduleFullScreen] = useState<boolean>(false);

    const chartHeadRef = useRef<SimpleBarCore>(null);
    const simpleBarRef = useRef<SimpleBarCore>(null);

    const scheduleTournaments = useMemo(() => {
        if (!tournaments) {
            return [];
        }

        const startDate = moment().add(-10, 'days');
        const endDate = moment().add(90, 'days');

        return tournaments
            .filter(
                (tournament) =>
                    ((isCheckedPrimary && tournament.is_primary === 1) ||
                        (isCheckedNonPrimary && tournament.is_primary === 0)) &&
                    ((moment(tournament.date).isSameOrAfter(startDate, 'day') &&
                        moment(tournament.date).isSameOrBefore(endDate, 'day')) ||
                        (tournament.date_final &&
                            moment(tournament.date_final).isSameOrAfter(startDate, 'day') &&
                            moment(tournament.date).isSameOrBefore(endDate, 'day'))),
            )
            .sort((a, b) => (moment(a.date).isAfter(moment(b.date)) ? 1 : -1));
    }, [tournaments, isCheckedPrimary, isCheckedNonPrimary]);

    const monthList = useMemo(() => {
        const startDate = moment().add(-10, 'days');
        const endDate = moment().add(90, 'days');

        const dateArray = [];
        let currentDate = startDate.clone();
        while (currentDate.isBefore(endDate)) {
            dateArray.push(currentDate);
            currentDate = currentDate.clone().add(1, 'day');
        }

        const _monthList: { [key: string]: { title: string; days: Moment[] } } = {};

        dateArray.forEach((date) => {
            const month = _monthList[date.format('MMM YYYY')]
                ? { ..._monthList[date.format('MMM YYYY')] }
                : { title: date.format('MMM YYYY'), days: [] };

            month.days.push(date);

            _monthList[date.format('MMM YYYY')] = month;
        });

        return _monthList;
    }, []);

    const recalculateElements = () => {
        setChartDayWidth(document.querySelectorAll('.chart-day')[0]?.clientWidth || 34);
        setChartSidebarItemHeight(
            Array.from(document.querySelectorAll('.chart-sidebar__item')).map((_) => _.clientHeight || 0),
        );
    };

    useEffect(() => {
        dragscroll.reset();
        recalculateElements();
    }, [scheduleTournaments]);

    useEffect(() => {
        recalculateElements();
        window.addEventListener('resize', recalculateElements);

        return () => {
            window.removeEventListener('resize', recalculateElements, true);
        };
    }, []);

    return (
        <section
            className="section"
            style={
                isScheduleFullScreen
                    ? { position: 'fixed', zIndex: 1000, background: '#1A1A1A', top: 0, bottom: 0, left: 0, right: 0 }
                    : undefined
            }
        >
            <div className="section-inner">
                <div className="schedule">
                    <div className="schedule-top">
                        <div className="container--sm">
                            <h2 className="schedule-title">schedule tournaments</h2>
                            <div
                                className={`schedule-nav fullscreen-panel ${isScheduleFullScreen ? 'fixed' : ''}`}
                                id="fullscreen-panel"
                            >
                                <div className="schedule-nav__inner">
                                    <div className="schedule-nav__content">
                                        <div className="schedule-nav__items">
                                            <div className="schedule-nav__item">
                                                <div className="checkbox checkbox--indicator-green">
                                                    <label className="checkbox-label">
                                                        <input
                                                            className="checkbox-input"
                                                            type="checkbox"
                                                            checked={isCheckedPrimary}
                                                            onChange={() => setCheckedPrimary(!isCheckedPrimary)}
                                                        />
                                                        <div className="checkbox-content">
                                                            <div className="checkbox-style"></div>
                                                            <div className="checkbox-text h6">Primary tournaments</div>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="schedule-nav__item">
                                                <div className="checkbox checkbox--indicator-purple">
                                                    <label className="checkbox-label">
                                                        <input
                                                            className="checkbox-input"
                                                            type="checkbox"
                                                            checked={isCheckedNonPrimary}
                                                            onChange={() => setCheckedNonPrimary(!isCheckedNonPrimary)}
                                                        />
                                                        <div className="checkbox-content">
                                                            <div className="checkbox-style"></div>
                                                            <div className="checkbox-text h6">
                                                                non-primary tournaments
                                                            </div>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="schedule-nav__resize">
                                            <a
                                                className="btn btn--icon js-fullscreen"
                                                onClick={() => setScheduleFullScreen(!isScheduleFullScreen)}
                                            >
                                                <span className="btn-icon">
                                                    <span className="btn-icon--default">
                                                        <svg className="icon">
                                                            <use href={`${MainSymbols}#image-resize-open`}></use>
                                                        </svg>
                                                    </span>
                                                    <span className="btn-icon--active">
                                                        <svg className="icon">
                                                            <use href={`${MainSymbols}#image-resize-close`}></use>
                                                        </svg>
                                                    </span>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="schedule-content fullscreen-content" id="fullscreen-content">
                        <div className="container">
                            <div className="schedule-chart">
                                <div className="chart">
                                    <div className="chart-sidebar">
                                        {scheduleTournaments.map((tournament) => {
                                            return (
                                                <Link
                                                    className="chart-sidebar__item"
                                                    to={`/tournaments/${tournament.slug}`}
                                                    key={tournament.id}
                                                >
                                                    <div className="h6 chart-sidebar__item-title">
                                                        <span className={tournament.is_primary ? 'primary' : 'purple'}>
                                                            {tournament.organizer_name}{' '}
                                                        </span>
                                                        {tournament.title}
                                                    </div>
                                                    <div className="prop chart-sidebar__item-prop">
                                                        {tournament.type_id
                                                            ? getTournamentTypeById(tournament.type_id)?.name
                                                            : ''}
                                                    </div>
                                                </Link>
                                            );
                                        })}
                                    </div>

                                    <div className="chart-main" style={{ overflow: 'hidden' }}>
                                        <div className="chart-inner">
                                            <SimpleBar
                                                className="chart-head"
                                                style={{ zIndex: 0 }}
                                                ref={chartHeadRef}
                                                draggable
                                                classNames={{ contentWrapper: 'dragscroll' }}
                                                onScrollCapture={() => {
                                                    simpleBarRef.current?.contentWrapperEl?.scroll({
                                                        left: chartHeadRef.current?.contentWrapperEl?.scrollLeft,
                                                    });
                                                }}
                                            >
                                                <div className="chart-head__inner">
                                                    <div className="chart-months">
                                                        {Object.keys(monthList).map((monthKey) => {
                                                            const month = monthList[monthKey];

                                                            return (
                                                                <div className="chart-month" key={monthKey}>
                                                                    <div className="chart-month__title">
                                                                        {month.title}
                                                                    </div>
                                                                    <div className="chart-month__days">
                                                                        {month.days.map((day) => {
                                                                            return (
                                                                                <div
                                                                                    className="chart-day"
                                                                                    key={day.toISOString()}
                                                                                >
                                                                                    <div className="chart-day__num">
                                                                                        {day.format('D')}
                                                                                    </div>
                                                                                    <div className="chart-day__name">
                                                                                        {day
                                                                                            .format('dddd')
                                                                                            .substring(0, 1)}
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </SimpleBar>

                                            <SimpleBar
                                                className="chart-content"
                                                draggable
                                                classNames={{ contentWrapper: 'dragscroll' }}
                                                style={{ overflowY: 'hidden' }}
                                                ref={simpleBarRef}
                                                onScrollCapture={() => {
                                                    chartHeadRef.current?.contentWrapperEl?.scroll({
                                                        left: simpleBarRef.current?.contentWrapperEl?.scrollLeft,
                                                    });
                                                }}
                                            >
                                                <div className="chart-content__inner">
                                                    <div className="chart-indicators">
                                                        {Object.keys(monthList).map((monthKey) => {
                                                            const month = monthList[monthKey];

                                                            return (
                                                                <>
                                                                    {month.days.map((day) => {
                                                                        return (
                                                                            <div
                                                                                className={`chart-indicator ${
                                                                                    day.isSame(moment(), 'day')
                                                                                        ? 'active'
                                                                                        : ''
                                                                                }`}
                                                                                key={day.toISOString()}
                                                                            ></div>
                                                                        );
                                                                    })}
                                                                </>
                                                            );
                                                        })}
                                                    </div>
                                                    <div className="chart-rows">
                                                        {scheduleTournaments.map((tournament, index) => {
                                                            const tourType = tournament.type_id
                                                                ? getTournamentTypeById(tournament.type_id)?.name
                                                                : '';

                                                            const tourDate = moment(tournament.date).format(
                                                                'D MMMM YYYY',
                                                            );
                                                            const tourTime = tournament.time.substring(0, 5);
                                                            const allDays: Moment[] = [];
                                                            Object.keys(monthList).forEach((_) =>
                                                                allDays.push(...monthList[_].days),
                                                            );
                                                            const tourStart = allDays
                                                                .map((_) => _.format('D MMMM YYYY'))
                                                                .findIndex((_) => _ === tourDate);
                                                            const startDate = moment().add(-10, 'days');
                                                            const tournamentStartDate =
                                                                tournament.date_final &&
                                                                moment(tournament.date).isSameOrAfter(startDate, 'day')
                                                                    ? moment(tournament.date)
                                                                    : startDate;

                                                            const tourDuration = tournament.date_final
                                                                ? moment(tournament.date_final).diff(
                                                                      tournamentStartDate,
                                                                      'days',
                                                                  ) + 1
                                                                : 5;

                                                            const tourHasFinalDate =
                                                                !!tournament.date_final &&
                                                                moment(tournament.date_final).diff(
                                                                    moment(tournament.date),
                                                                    'days',
                                                                ) > 0;

                                                            const divTour = (
                                                                <div className="chart-drop">
                                                                    <div className="chart-drop__title h6">
                                                                        <span className="{$colorclassName}">
                                                                            {tournament.organizer_name}
                                                                        </span>{' '}
                                                                        {tournament.title}
                                                                    </div>
                                                                    <div className="chart-drop__items">
                                                                        <div className="chart-drop__item">
                                                                            <div className="chart-drop__prop prop">
                                                                                Type
                                                                            </div>
                                                                            <div className="chart-drop__value prop">
                                                                                {tourType}
                                                                            </div>
                                                                        </div>
                                                                        <div className="chart-drop__item">
                                                                            <div className="chart-drop__prop prop">
                                                                                Date
                                                                            </div>
                                                                            <div className="chart-drop__value prop">
                                                                                {tourDate}
                                                                            </div>
                                                                        </div>
                                                                        <div className="chart-drop__item">
                                                                            <div className="chart-drop__prop prop">
                                                                                Time
                                                                            </div>
                                                                            <div className="chart-drop__value prop">
                                                                                {tourTime}
                                                                            </div>
                                                                        </div>
                                                                        {!!tournament.prize_one?.trim() && (
                                                                            <div className="chart-drop__item">
                                                                                <div className="chart-drop__prop prop">
                                                                                    1st place prize
                                                                                </div>
                                                                                <div className="chart-drop__value prop">
                                                                                    {tournament.prize_one}
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        {!!tournament.prize_two?.trim() && (
                                                                            <div className="chart-drop__item">
                                                                                <div className="chart-drop__prop prop">
                                                                                    2nd place prize
                                                                                </div>
                                                                                <div className="chart-drop__value prop">
                                                                                    {tournament.prize_two}
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        {!!tournament.prize_three?.trim() && (
                                                                            <div className="chart-drop__item">
                                                                                <div className="chart-drop__prop prop">
                                                                                    3rd place prize
                                                                                </div>
                                                                                <div className="chart-drop__value prop">
                                                                                    {tournament.prize_three}
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        {!!tournament.prize_four?.trim() && (
                                                                            <div className="chart-drop__item">
                                                                                <div className="chart-drop__prop prop">
                                                                                    4th place prize
                                                                                </div>
                                                                                <div className="chart-drop__value prop">
                                                                                    {tournament.prize_four}
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            );

                                                            return (
                                                                <div
                                                                    className="chart-row"
                                                                    key={tournament.id}
                                                                    style={{
                                                                        height: `${
                                                                            chartSidebarItemHeight[index] || 60
                                                                        }px`,
                                                                    }}
                                                                >
                                                                    <Link
                                                                        id={`link-${tournament.slug}`}
                                                                        to={`/tournaments/${tournament.slug}`}
                                                                        className={`chart-event chart-event--${
                                                                            tournament.is_primary ? 'green' : 'purple'
                                                                        } ${
                                                                            tourHasFinalDate ? '' : 'chart-event--half'
                                                                        }`}
                                                                        style={{
                                                                            width: `${chartDayWidth * tourDuration}px`,
                                                                            left: `${
                                                                                chartDayWidth *
                                                                                (tourStart === -1 ? 0 : tourStart)
                                                                            }px`,
                                                                        }}
                                                                    ></Link>
                                                                    <Tooltip
                                                                        anchorSelect={`#link-${tournament.slug}`}
                                                                        place="top"
                                                                        positionStrategy="fixed"
                                                                        noArrow
                                                                    >
                                                                        {divTour}
                                                                    </Tooltip>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </SimpleBar>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
