import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';

import logo from '~app/assets/img/logo.png';
import MainSymbol from '~app/assets/sprites/main.symbol.svg';
import { useRootContext } from '~app/contexts';

export const Header = (): JSX.Element => {
    const { showLoader } = useRootContext();
    const { pathname } = useLocation();

    const [isHeaderFixed, setHeaderFixed] = useState<boolean>(false);
    const headerDropRef = useRef<HTMLDivElement>(null);

    const headerScroll = useCallback(() => {
        if (window.scrollY > 0) {
            setHeaderFixed(true);
        } else {
            setHeaderFixed(false);
        }
    }, []);

    const openMenu = useCallback(() => {
        if (headerDropRef.current) {
            headerDropRef.current.className = 'header-drop active anime';
        }
    }, [headerDropRef]);

    const handleMenuItemClick = useCallback(
        (newPathname: string | null) => {
            if (newPathname !== null && pathname !== newPathname) {
                showLoader();
            }

            if (headerDropRef.current) {
                headerDropRef.current.className = 'header-drop';
            }
        },
        [headerDropRef, pathname],
    );

    useEffect(() => {
        window.addEventListener('scroll', headerScroll);

        return () => {
            window.removeEventListener('scroll', headerScroll);
        };
    }, []);

    return (
        <header className={`header ${isHeaderFixed ? 'fixed' : ''}`}>
            <div className="container">
                <div className="header-container">
                    <div className="header-btn js-header-btn" onClick={openMenu}>
                        <div className="header-btn__inner">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                    <div className="header-logo">
                        <Link className="logo" to="/">
                            <img className="logo-img" src={logo} alt="" />
                        </Link>
                    </div>
                    <div className="header-drop" ref={headerDropRef}>
                        <div className="close js-close" onClick={() => handleMenuItemClick(null)}>
                            <div className="close-inner">
                                <div className="close-icon">
                                    <svg className="icon">
                                        <use href={`${MainSymbol}#image-chevron`}></use>
                                    </svg>
                                </div>
                                <div className="close-text">close</div>
                            </div>
                        </div>
                        <div className="header-drop__inner">
                            <div className="header-menu">
                                <ul className="header-menu__list">
                                    <li className="header-menu__item">
                                        <NavLink
                                            className="header-menu__link"
                                            to="/tournaments"
                                            onClick={() => handleMenuItemClick('/tournaments')}
                                        >
                                            Tournaments
                                        </NavLink>
                                    </li>
                                    <li className="header-menu__item">
                                        <NavLink
                                            className="header-menu__link"
                                            to="/champions"
                                            onClick={() => handleMenuItemClick('/champions')}
                                        >
                                            Champs
                                        </NavLink>
                                    </li>
                                    <li className="header-menu__item">
                                        <NavLink
                                            className="header-menu__link"
                                            to="/media"
                                            onClick={() => handleMenuItemClick('/media')}
                                        >
                                            Media
                                        </NavLink>
                                    </li>
                                    <li className="header-menu__item">
                                        <NavLink
                                            className="header-menu__link"
                                            to="/contacts"
                                            onClick={() => handleMenuItemClick('/contacts')}
                                        >
                                            Contacts
                                        </NavLink>
                                    </li>
                                    <li className="header-menu__item">
                                        <NavLink
                                            className="header-menu__link"
                                            to="/donate"
                                            onClick={() => handleMenuItemClick('/donate')}
                                        >
                                            Donate
                                        </NavLink>
                                    </li>
                                    <li className="header-menu__item">
                                        <NavLink
                                            className="header-menu__link "
                                            to="/thanks"
                                            onClick={() => handleMenuItemClick('/thanks')}
                                        >
                                            Thanks
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/*<div class="header-controls">*/}
                    {/*  <div class="header-lang">*/}
                    {/*    <div class="dropdown">*/}
                    {/*      <div class="dropdown-result js-dropdown-btn">*/}
                    {/*        <div class="dropdown-result__text">En</div>*/}
                    {/*        <div class="dropdown-result__icon">*/}
                    {/*          <svg class="icon">*/}
                    {/*            <use href="<?= IMG_ROOT ?>/sprites/main.symbol.svg#image-chevron"></use>*/}
                    {/*          </svg>*/}
                    {/*        </div>*/}
                    {/*      </div>*/}
                    {/*      <div class="dropdown-box">*/}
                    {/*        <div class="close js-close">*/}
                    {/*          <div class="close-inner">*/}
                    {/*            <div class="close-icon">*/}
                    {/*              <svg class="icon">*/}
                    {/*                <use href="<?= IMG_ROOT ?>/sprites/main.symbol.svg#image-chevron"></use>*/}
                    {/*              </svg>*/}
                    {/*            </div>*/}
                    {/*            <div class="close-text">close</div>*/}
                    {/*          </div>*/}
                    {/*        </div>*/}
                    {/*        <div class="dropdown-box__inner">*/}
                    {/*          <ul class="dropdown-items">*/}
                    {/*            <?php foreach (Yii::$app->params['languages'] as $language): ?>*/}
                    {/*            <li class="dropdown-item">*/}
                    {/*              <a class="dropdown-link active" href="<?= Url::home() ?>"><?= $language ?></a>*/}
                    {/*            </li>*/}
                    {/*            <?php end foreach; ?>*/}
                    {/*          </ul>*/}
                    {/*        </div>*/}
                    {/*      </div>*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*  <div class="header-personal">*/}
                    {/*    <?php if (Yii::$app->user->isGuest) : ?>*/}
                    {/*    <a class="header-menu__link js-popup-open" href="#" data-popup="adminLogin">Login</a>*/}
                    {/*    <?php else : ?>*/}
                    {/*    <div class="dropdown">*/}
                    {/*      <div class="dropdown-result js-dropdown-btn">*/}
                    {/*        <div class="dropdown-result__media">*/}
                    {/*          <img class="dropdown-result__bg"*/}
                    {/*               src="<?= Yii::$app->user->identity->avatar ?: (IMG_ROOT . '/logo-big.png') ?>" alt=""/>*/}
                    {/*        </div>*/}
                    {/*        <div class="dropdown-result__icon">*/}
                    {/*          <svg class="icon">*/}
                    {/*            <use href="<?= IMG_ROOT ?>/sprites/main.symbol.svg#image-chevron"></use>*/}
                    {/*          </svg>*/}
                    {/*        </div>*/}
                    {/*      </div>*/}
                    {/*      <div class="dropdown-box">*/}
                    {/*        <div class="close js-close">*/}
                    {/*          <div class="close-inner">*/}
                    {/*            <div class="close-icon">*/}
                    {/*              <svg class="icon">*/}
                    {/*                <use href="<?= IMG_ROOT ?>/sprites/main.symbol.svg#image-chevron"></use>*/}
                    {/*              </svg>*/}
                    {/*            </div>*/}
                    {/*            <div class="close-text">close</div>*/}
                    {/*          </div>*/}
                    {/*        </div>*/}
                    {/*        <div class="dropdown-box__inner">*/}
                    {/*          <ul class="dropdown-items">*/}
                    {/*            <li class="dropdown-item">*/}
                    {/*              <a class="dropdown-link" href="#"><?= Yii::$app->user->identity->email ?></a>*/}
                    {/*            </li>*/}
                    {/*          </ul>*/}
                    {/*          <ul class="dropdown-items">*/}
                    {/*            <?php if (Yii::$app->user->can('createTournament')) : ?>*/}
                    {/*            <li class="dropdown-item">*/}
                    {/*              <a class="dropdown-link js-ajax-popup"*/}
                    {/*                 data-url="<?= Url::to(['/tournament/create']) ?>" href="#">*/}
                    {/*                Create tournament*/}
                    {/*              </a>*/}
                    {/*            </li>*/}
                    {/*            <?php endif; ?>*/}

                    {/*            <?php if (Yii::$app->user->can('root')) : ?>*/}
                    {/*            <li class="dropdown-item">*/}
                    {/*              <a class="dropdown-link js-ajax-popup"*/}
                    {/*                 data-url="<?= Url::to(['/site-media/update']) ?>" href="#">*/}
                    {/*                Create media*/}
                    {/*              </a>*/}
                    {/*            </li>*/}
                    {/*            <?php endif; ?>*/}

                    {/*            <li class="dropdown-item">*/}
                    {/*              <a class="dropdown-link" href="<?= Url::to(['/cabinet/tournaments']) ?>">my tournaments</a>*/}
                    {/*            </li>*/}

                    {/*            <?php if (Yii::$app->user->can('root')) : ?>*/}
                    {/*            <li class="dropdown-item">*/}
                    {/*              <a class="dropdown-link" href="<?= Url::to(['/cabinet/index']) ?>">edit users</a>*/}
                    {/*            </li>*/}
                    {/*            <?php endif; ?>*/}
                    {/*          </ul>*/}
                    {/*          <ul class="dropdown-items">*/}
                    {/*            <li class="dropdown-item">*/}
                    {/*              <a class="dropdown-link" href="<?= Url::to(['/cabinet/profile']) ?>">settings</a>*/}
                    {/*            </li>*/}
                    {/*            <li class="dropdown-item">*/}
                    {/*              <a class="dropdown-link" href="<?= Url::to(['/cabinet/logout']) ?>" data-method="post">logout</a>*/}
                    {/*            </li>*/}
                    {/*          </ul>*/}
                    {/*        </div>*/}
                    {/*      </div>*/}
                    {/*    </div>*/}
                    {/*    <?php endif; ?>*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </header>
    );
};
