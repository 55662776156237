import throttle from 'lodash/throttle';
import React, { useEffect, useRef, useState } from 'react';

export enum VisibilityType {
    FULL,
    HORIZONTAL,
    VERTICAL,
}
export const useVisibility = <Element extends HTMLElement>(
    type = VisibilityType.FULL,
    offset = 0,
    throttleMilliseconds = 100,
): [boolean, React.RefObject<Element>] => {
    const [isVisible, setIsVisible] = useState(false);
    const currentElement = useRef<Element>(null);

    const onScroll = throttle(() => {
        if (!currentElement.current) {
            setIsVisible(false);

            return;
        }

        const left = currentElement.current.getBoundingClientRect().left;
        const right = currentElement.current.getBoundingClientRect().right;
        // const top = currentElement.current.getBoundingClientRect().top;
        // const bottom = currentElement.current.getBoundingClientRect().bottom;

        switch (type) {
            case VisibilityType.HORIZONTAL: {
                setIsVisible(
                    left + offset >= 0 &&
                        left - offset <= window.innerWidth &&
                        right - offset >= 0 &&
                        right + offset <= window.innerWidth,
                );

                break;
            }
            case VisibilityType.VERTICAL:
            case VisibilityType.FULL:
            default: {
                throw new Error(`Unsupported type: ${type}`);
            }
        }
    }, throttleMilliseconds);

    useEffect(() => {
        onScroll();
        document.addEventListener('scroll', onScroll, true);
        window.addEventListener('resize', onScroll);

        return () => {
            document.removeEventListener('scroll', onScroll, true);
            window.removeEventListener('resize', onScroll, true);
        };
    });

    return [isVisible, currentElement];
};
