import React from 'react';
import { Link } from 'react-router-dom';

import logo from '~app/assets/img/logo.png';

export const Footer = (): JSX.Element => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="footer-container">
                    <div className="footer-logo">
                        <Link className="logo" to="/">
                            <img className="logo-img" src={logo} alt="" />
                        </Link>
                    </div>
                    <div className="footer-content">
                        <div className="footer-menu">
                            <ul className="footer-menu__list">
                                <li className="footer-menu__item">
                                    <Link className="footer-menu__link" to="/contacts">
                                        Contacts
                                    </Link>
                                </li>
                                <li className="footer-menu__item">
                                    <Link className="footer-menu__link" to="/terms-of-services">
                                        Terms of services
                                    </Link>
                                </li>
                                <li className="footer-menu__item">
                                    <Link className="footer-menu__link" to="/privacy-policy">
                                        Privacy policy
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};
