import React from 'react';
import { Link } from 'react-router-dom';
import SimpleBar from 'simplebar-react';

import { useRootContext } from '~app/contexts';

interface ITournamentNavigationProps {
    onClick: (tab: string) => void;
    tab: string;
}
export const TournamentNavigation = ({ onClick, tab }: ITournamentNavigationProps): JSX.Element => {
    const { isGuest } = useRootContext();

    return (
        <SimpleBar className="nav">
            <div className="container--sm">
                <div className="nav-container">
                    <ul className="nav-list">
                        <li className="nav-item">
                            <a
                                className={`nav-link ${tab === 'brackets' ? 'active' : ''}`}
                                onClick={() => onClick('brackets')}
                            >
                                brackets
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className={`nav-link ${tab === 'participants' ? 'active' : ''}`}
                                onClick={() => onClick('participants')}
                            >
                                participants
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className={`nav-link ${tab === 'schedule' ? 'active' : ''}`}
                                onClick={() => onClick('schedule')}
                            >
                                schedule
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className={`nav-link ${tab === 'rules' ? 'active' : ''}`}
                                onClick={() => onClick('rules')}
                            >
                                rules
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className={`nav-link ${tab === 'prizes' ? 'active' : ''}`}
                                onClick={() => onClick('prizes')}
                            >
                                prizes
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className={`nav-link ${tab === 'media' ? 'active' : ''}`}
                                onClick={() => onClick('media')}
                            >
                                media
                            </a>
                        </li>
                    </ul>
                    {isGuest && (
                        <div className="nav-controls">
                            <Link className="btn btn--dark" to="/contacts">
                                how to register
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        </SimpleBar>
    );
};
