import React, { useMemo } from 'react';

import bg5 from '~app/assets/img/bg5.jpg';
import { useRootContext, useTournamentsContext } from '~app/contexts';
import { Share } from '~app/pages/components/share';
import { TournamentParticipant } from '~app/pages/components/tournament-participant';
import { TournamentWinners } from '~app/pages/components/tournament-winners';
import { IParticipant } from '~app/types/participant.interface';

export const TournamentParticipants = (): JSX.Element | null => {
    const { getTournamentTypeById } = useRootContext();
    const { tournament } = useTournamentsContext();

    const [isTeams, teams] = useMemo(() => {
        const _isTeams = getTournamentTypeById(tournament?.type_id || -1)?.description === 'teams';
        const _teams: { [key: string]: { name: string; participants: IParticipant[] } } = {};

        if (_isTeams && tournament?.participants) {
            Object.keys(tournament.participants).forEach((participantId) => {
                if (tournament.participants) {
                    const participant = tournament.participants[+participantId];
                    const team = _teams[participant.team_id] || { name: participant.team_name, participants: [] };
                    team.participants.push(participant);
                    _teams[participant.team_id] = team;
                }
            });
        }

        return [_isTeams, _teams];
    }, [tournament]);

    if (!tournament?.participants) {
        return null;
    }

    return (
        <>
            <TournamentWinners />
            <section className="section section--main section--sm">
                <div className="section-bg">
                    <div className="section-bg__img" style={{ backgroundImage: `url(${bg5})` }}></div>
                </div>
                <div className="section-inner">
                    <div className="container--sm">
                        {isTeams && (
                            <div className="participants-teams">
                                {Object.keys(teams).map((team_id) => {
                                    const team = teams[team_id];

                                    if (!team) {
                                        return null;
                                    }

                                    return (
                                        <div className="participants-team" key={team_id}>
                                            <div className="participants">
                                                <div className="participants-title h6">{team.name}</div>
                                                <div className="participants">
                                                    {team.participants?.map((participant) => (
                                                        <TournamentParticipant
                                                            participant={participant}
                                                            key={participant.id}
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                        {!isTeams && (
                            <div className="participants">
                                {Object.keys(tournament.participants || {}).map((participantId) => (
                                    <TournamentParticipant
                                        participant={(tournament.participants || {})[+participantId]}
                                        key={participantId}
                                    />
                                ))}
                            </div>
                        )}

                        <Share />
                    </div>
                </div>
            </section>
        </>
    );
};
