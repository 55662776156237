import React, { useEffect } from 'react';

import bg9 from '~app/assets/img/bg9.jpg';
import { useRootContext } from '~app/contexts';
import { Empty } from '~app/pages/components/empty';

export const TermsOfServicesPage = (): JSX.Element => {
    const { hideLoader } = useRootContext();

    useEffect(() => {
        hideLoader();
    }, []);

    return (
        <>
            <section className="section section--head">
                <div className="section-bg">
                    <div className="section-bg__overlay">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <div className="section-bg__img" style={{ backgroundImage: `url(${bg9})` }} />
                </div>
                <div className="section-inner">
                    <div className="container">
                        <div className="section-title">
                            <h1 className="h2">Terms of services</h1>
                        </div>
                    </div>
                </div>
            </section>

            <Empty />
        </>
    );
};
