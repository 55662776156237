import React, { useEffect } from 'react';

import bg5 from '~app/assets/img/bg5.jpg';
import bg14 from '~app/assets/img/bg14.jpg';
import { useMediaContext, useRootContext } from '~app/contexts';
import { Empty } from '~app/pages/components/empty';
import { MediaCard } from '~app/pages/components/media-card';
import { MediaListFilter } from '~app/pages/components/media-list-filter';

export const MediaListPage = (): JSX.Element | null => {
    const { hideLoader } = useRootContext();
    const { mediaList, fetchMediaList, clearMediaData } = useMediaContext();

    useEffect(() => {
        fetchMediaList().then();

        return () => {
            clearMediaData();
        };
    }, []);

    useEffect(() => {
        if (mediaList !== null) {
            hideLoader();
        }
    }, [mediaList]);

    return (
        <div id="media-list">
            <section className="section section--head">
                <div className="section-bg">
                    <div className="section-bg__overlay">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <div className="section-bg__img" style={{ backgroundImage: `url(${bg14})` }}></div>
                </div>
                <div className="section-inner">
                    <div className="container">
                        <div className="section-title">
                            <h1 className="h2">
                                all media <span>/ {mediaList?.length || '-'}</span>
                            </h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section section--main section--sm">
                <div className="section-bg">
                    <div className="section-bg__img" style={{ backgroundImage: `url(${bg5})` }}></div>
                </div>
                <div className="section-inner">
                    <div className="container--sm">
                        <MediaListFilter style={{ zIndex: 2 }} />
                        {!mediaList?.length && <Empty />}
                        {!!mediaList?.length && (
                            <div className="filter-content">
                                <div className="mb">
                                    <div className="news news--lg">
                                        <div className="news-inner">
                                            {mediaList.map((media) => {
                                                return <MediaCard media={media} key={media.id} />;
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <MediaListFilter />
                    </div>
                </div>
            </section>
        </div>
    );
};
