import { IBracket } from '~app/types/bracket.interface';
import { IParticipant } from '~app/types/participant.interface';
import { IPrize } from '~app/types/prize.interface';
import { IRule } from '~app/types/rule.interface';
import { ISchedule } from '~app/types/schedule.interface';
import { ITournamentMedia } from '~app/types/tournament-media.interface';
import { IWinner } from '~app/types/winner.interface';

export enum TournamentStatus {
    STATUS_PENDING,
    STATUS_IN_PROGRESS,
    STATUS_COMPLETED,
}
export enum TournamentOrder {
    DATE,
    POOL,
    TYPE,
    STATUS,
    TITLE,
}

export const TOURNAMENT_STATUS_DICT = new Map([
    [TournamentStatus.STATUS_PENDING, 'Pending'],
    [TournamentStatus.STATUS_IN_PROGRESS, 'In progress'],
    [TournamentStatus.STATUS_COMPLETED, 'Completed'],
]);

export const SELECT_STATUS_OPTIONS = [
    { value: -1, label: 'All' },
    { value: TournamentStatus.STATUS_PENDING, label: 'Pending' },
    { value: TournamentStatus.STATUS_IN_PROGRESS, label: 'In progress' },
    { value: TournamentStatus.STATUS_COMPLETED, label: 'Completed' },
];

export const SELECT_ORDER_OPTIONS = [
    { value: TournamentOrder.DATE, label: 'Date' },
    { value: TournamentOrder.POOL, label: 'Prize pool' },
    { value: TournamentOrder.TYPE, label: 'Type' },
    { value: TournamentOrder.STATUS, label: 'Status' },
    { value: TournamentOrder.TITLE, label: 'Title' },
];

export interface ITournament {
    id: number;
    title: string;
    slug: string;
    status: TournamentStatus;
    pool: number;
    date: string;
    date_final: string;
    time: string;
    time_final: string;
    type_id: number;
    bg_image: string;
    organizer_id: number;
    organizer_name: string;
    language_id: number;
    language_name: string;
    created_by: number;
    updated_by: number;
    created_at: string;
    updated_at: string;
    time_zone: number;
    prize_one: string | null;
    prize_two: string | null;
    prize_three: string | null;
    prize_four: string | null;
    schedule_type: number;
    show_on_main_page: number;
    is_primary: number;
    team_mode: number;
    pool_custom: string;
    participants?: { [key: number]: IParticipant };
    brackets?: IBracket[];
    schedules?: ISchedule[];
    media?: ITournamentMedia[];
    prizes?: IPrize[];
    rules?: IRule[];
    winners?: {
        secondary: IWinner[];
        special: IWinner[];
        standard: IWinner[];
    };
}
