import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactPaginate from 'react-paginate';

import bg5 from '~app/assets/img/bg5.jpg';
import bg14 from '~app/assets/img/bg14.jpg';
import { useRootContext } from '~app/contexts';
import { useTournamentsContext } from '~app/contexts/TournamentsContext';
import { Empty } from '~app/pages/components/empty';
import { TournamentCard } from '~app/pages/components/tournament-card';
import { TournamentsFilter } from '~app/pages/components/tournaments-filter';
import { TournamentsSchedule } from '~app/pages/components/tournaments-schedule';

export const TournamentsPage = (): JSX.Element => {
    const ITEMS_PER_PAGE = 10;

    const { hideLoader } = useRootContext();
    const { tournaments, fetchTournaments, clearTournamentsData } = useTournamentsContext();

    const [itemOffset, setItemOffset] = useState<number>(0);

    const pageCount = useMemo(() => {
        return Math.ceil((tournaments?.length || 0) / ITEMS_PER_PAGE);
    }, [tournaments]);

    const endOffset = useMemo(() => itemOffset + ITEMS_PER_PAGE, [itemOffset]);

    const handlePageClick = useCallback(
        (event: any) => {
            const newOffset = (event.selected * ITEMS_PER_PAGE) % (tournaments?.length || 0);
            setItemOffset(newOffset);
        },
        [tournaments],
    );

    useEffect(() => {
        fetchTournaments().then();

        return () => {
            clearTournamentsData();
        };
    }, []);

    useEffect(() => {
        if (tournaments !== null) {
            hideLoader();
        }
    }, [tournaments]);

    return (
        <>
            <section className="section section--head">
                <div className="section-bg">
                    <div className="section-bg__overlay">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <div className="section-bg__img" style={{ backgroundImage: `url(${bg14}` }}></div>
                </div>
                <div className="section-inner">
                    <div className="container">
                        <div className="section-title">
                            <h1 className="h2">
                                all tournaments <span>/ {tournaments?.length}</span>
                            </h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section section--main section--sm">
                <div className="section-bg">
                    <div className="section-bg__img" style={{ backgroundImage: `url(${bg5}` }}></div>
                </div>
                <div className="section-inner">
                    <div className="container--sm">
                        <TournamentsFilter style={{ zIndex: 2 }} />
                        {!!tournaments?.length && (
                            <div className="filter-content">
                                <div className="mb">
                                    <div className="tourneys tourneys--lg">
                                        <div className="tourneys-inner">
                                            {tournaments.slice(itemOffset, endOffset).map((tournament) => (
                                                <TournamentCard tournament={tournament} key={tournament.id} />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel="NEXT"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                            previousLabel="PREV"
                            renderOnZeroPageCount={null}
                            className={'pagination'}
                            activeLinkClassName={'active'}
                            pageLinkClassName={'btn btn--dark'}
                            nextClassName={'pagination-btn btn btn--dark'}
                            previousClassName={'pagination-btn btn btn--dark'}
                        />
                        {!tournaments || (tournaments.length === 0 && <Empty />)}
                        <TournamentsFilter />
                    </div>
                </div>
            </section>

            <TournamentsSchedule />
        </>
    );
};
