import React, { useCallback, useMemo, useState } from 'react';
import SimpleBar from 'simplebar-react';

import { useRootContext, useTournamentsContext } from '~app/contexts';
import { Image } from '~app/pages/components/image';
import { makeS3ResourceLink, primers, sort_by } from '~app/utils';

export const TournamentTableBracket = (): JSX.Element => {
    const { getAvatar } = useRootContext();
    const { currentBracket, tournament } = useTournamentsContext();

    const [search, setSearch] = useState<string>('');
    const [tableSortState, setTableSortState] = useState<{ field: string; order: string }>({
        field: tournament?.team_mode === 1 ? 'team' : 'nick',
        order: 'asc',
    });

    const headers = useMemo(() => {
        if (!currentBracket || !tournament) {
            return [];
        }

        const playerHeaders = [
            ['player', 'nick', 'true'],
            ['class', 'class_name', 'true'],
            ['faction', 'faction', 'true'],
            ['game world', 'world', 'true'],
        ];
        const teamHeaders = [['team', 'team', 'true']];

        return [
            ...(tournament.team_mode === 1 ? teamHeaders : playerHeaders),
            ...currentBracket.headers.filter((_) => _.toLowerCase() !== 'top').map((_) => [_]),
        ];
    }, [tournament, currentBracket]);

    const withTop = useMemo(() => {
        const _withTop = currentBracket?.headers.includes('top');

        if (_withTop) {
            setTableSortState({ field: 'top', order: 'asc' });
        }
        return _withTop;
    }, [currentBracket]);

    const rows = useMemo(() => {
        if (!currentBracket) {
            return [];
        }

        return currentBracket.rows
            .map((row) => {
                const newRow: { [key: string]: any } = { ...row };
                delete newRow.columns;
                row.columns.forEach((c) => {
                    newRow[c.title] = c.title !== 'top' ? c.value : row.top || -1;
                });

                const player = tournament?.participants ? tournament?.participants[row.player_id] : {};

                return { ...newRow, ...player };
            })
            .sort(
                sort_by(
                    tableSortState.field,
                    tableSortState.order === 'asc',
                    primers[tableSortState.field.toLowerCase()],
                ),
            )
            .filter((row: any) => {
                if (!search || search === '') {
                    return true;
                }

                if (tournament?.team_mode === 1) {
                    return row.team?.toLowerCase().includes(search.toLowerCase());
                }

                return row.nick?.toLowerCase().includes(search.toLowerCase());
            });
    }, [tournament, currentBracket, search, tableSortState]);

    const handleSortBtnClick = useCallback((field: string) => {
        setTableSortState((prev) => {
            if (prev.field === field) {
                return {
                    field,
                    order: prev.order === 'asc' ? 'desc' : 'asc',
                };
            }

            return {
                field,
                order: 'asc',
            };
        });
    }, []);

    return (
        <div className="filter-content" id="table-container">
            <div className="container--sm">
                <div className="tabs active">
                    <div className="tabs-item">
                        <SimpleBar className="filter mb">
                            <div className="filter-main">
                                <div className="filter-wrap">
                                    <div className="filter-inner">
                                        <div className="filter-search">
                                            <div className="filter-search__text">find</div>
                                            <div className="filter-search__field">
                                                <input
                                                    type="text"
                                                    id="brackettablerowsearch-nick"
                                                    className="field field--sm"
                                                    name="nick"
                                                    placeholder={
                                                        tournament?.team_mode === 1
                                                            ? 'enter name of team'
                                                            : 'enter name of player'
                                                    }
                                                    value={search}
                                                    onChange={(event) => setSearch(event.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SimpleBar>
                        <div className="filter-content filter-content--sm">
                            <div className="groups">
                                <div className="group-wrap">
                                    <div className="group group--admin">
                                        <div className="mb">
                                            <div className="bracket-table champs--lg">
                                                <div className="bracket-table-inner">
                                                    <div className="table table--center">
                                                        <div className="table-content">
                                                            <div className="table-inner">
                                                                <table>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>
                                                                                <div
                                                                                    className={`${
                                                                                        withTop ? 'table-sort' : ''
                                                                                    } ${
                                                                                        withTop &&
                                                                                        tableSortState.field === 'top'
                                                                                            ? tableSortState.order !==
                                                                                              'asc'
                                                                                                ? 'up'
                                                                                                : 'bottom'
                                                                                            : ''
                                                                                    }`}
                                                                                >
                                                                                    <div className="table-sort__text">
                                                                                        #
                                                                                    </div>
                                                                                    <div
                                                                                        className="table-sort__btn"
                                                                                        onClick={() =>
                                                                                            handleSortBtnClick('top')
                                                                                        }
                                                                                    ></div>
                                                                                </div>
                                                                            </th>
                                                                            {headers.map((header, index) => (
                                                                                <th
                                                                                    className="table-col--left"
                                                                                    style={{
                                                                                        textAlign:
                                                                                            index > 0
                                                                                                ? 'center'
                                                                                                : 'left',
                                                                                    }}
                                                                                    key={header[0]}
                                                                                >
                                                                                    <div
                                                                                        className={`table-sort ${
                                                                                            tableSortState.field ===
                                                                                            (header[1] || header[0])
                                                                                                ? tableSortState.order !==
                                                                                                  'asc'
                                                                                                    ? 'up'
                                                                                                    : 'bottom'
                                                                                                : ''
                                                                                        }`}
                                                                                    >
                                                                                        <div className="table-sort__text">
                                                                                            {header[0]}
                                                                                        </div>
                                                                                        <div
                                                                                            className="table-sort__btn"
                                                                                            onClick={() =>
                                                                                                handleSortBtnClick(
                                                                                                    header[1] ||
                                                                                                        header[0],
                                                                                                )
                                                                                            }
                                                                                        ></div>
                                                                                    </div>
                                                                                </th>
                                                                            ))}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody id="table-body">
                                                                        {rows.map((row: any) => {
                                                                            const avatar = getAvatar(row);

                                                                            return (
                                                                                <tr key={row.id}>
                                                                                    <td>
                                                                                        {row.top >= 0 ? row.top : '-'}
                                                                                    </td>
                                                                                    {tournament?.team_mode === 0 && (
                                                                                        <>
                                                                                            <td className="table-col--left">
                                                                                                <div className="table-player">
                                                                                                    <div className="table-player__avatar">
                                                                                                        <Image
                                                                                                            img={
                                                                                                                avatar.img
                                                                                                            }
                                                                                                            webp={
                                                                                                                avatar.webp
                                                                                                            }
                                                                                                            defaultImg={
                                                                                                                avatar.defaultImg
                                                                                                            }
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="table-player__name"
                                                                                                        style={{
                                                                                                            color: row.color,
                                                                                                        }}
                                                                                                    >
                                                                                                        {row.external_link && (
                                                                                                            <a
                                                                                                                href={
                                                                                                                    row.external_link
                                                                                                                }
                                                                                                                className="no-decor"
                                                                                                                target="_blank"
                                                                                                                rel="noreferrer"
                                                                                                            >
                                                                                                                {
                                                                                                                    row.nick
                                                                                                                }
                                                                                                            </a>
                                                                                                        )}
                                                                                                        {!row.external_link &&
                                                                                                            row.nick}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>{row.class_name}</td>
                                                                                            <td>
                                                                                                <img
                                                                                                    className="table-logo"
                                                                                                    alt=""
                                                                                                    src={makeS3ResourceLink(
                                                                                                        row.faction_avatar,
                                                                                                    )}
                                                                                                />
                                                                                            </td>
                                                                                            <td>{row.world}</td>
                                                                                        </>
                                                                                    )}

                                                                                    {headers
                                                                                        .filter((_) => !_[2])
                                                                                        .map((header, index) => (
                                                                                            <td
                                                                                                key={header[0]}
                                                                                                className="table-col--left"
                                                                                                style={{
                                                                                                    textAlign: 'center',
                                                                                                }}
                                                                                            >
                                                                                                {row[header[0]] ?? '-'}
                                                                                            </td>
                                                                                        ))}
                                                                                </tr>
                                                                            );
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
