import moment from 'moment/moment';
import React, { CSSProperties, useCallback, useState } from 'react';
import DatePicker from 'react-datepicker';
import { PropsValue } from 'react-select';
import SimpleBar from 'simplebar-react';

import MainSymbols from '~app/assets/sprites/main.symbol.svg';
import { useMediaContext } from '~app/contexts';
import { Select } from '~app/pages/components/select';
import { SELECT_MEDIA_LIST_ORDER_OPTIONS, SELECT_MEDIA_LIST_TYPE_OPTIONS } from '~app/types/media.interface';

interface IMediaListFilterProps {
    style?: CSSProperties;
}

export const MediaListFilter = ({ style }: IMediaListFilterProps = { style: {} }): JSX.Element => {
    const { filter, updateFilter } = useMediaContext();

    const [isOpenDatepicker, setOpenDatepicker] = useState<boolean>(false);

    const handleDateChange = useCallback((date: Date | null) => {
        updateFilter('date', date);
        setOpenDatepicker(false);
    }, []);

    const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        updateFilter('search', event.target.value);
    }, []);

    const handleTypeChange = useCallback((selectedOption: PropsValue<{ value: number; label: string }>) => {
        updateFilter('type', selectedOption);
    }, []);

    const handleOrderChange = useCallback((selectedOption: PropsValue<{ value: number; label: string }>) => {
        updateFilter('order', selectedOption);
    }, []);

    return (
        <div style={{ position: 'relative' }}>
            <div style={{ height: '40px' }} />
            <div className="filter-bar-x-container" style={style}>
                <SimpleBar
                    className="filter"
                    ref={(ref) => {
                        if (ref?.contentWrapperEl) {
                            ref.contentWrapperEl.className = 'simplebar-content-wrapper filter-bar-x';
                        }
                    }}
                >
                    <div className="filter-main">
                        <div className="filter-wrap">
                            <div className="filter-inner">
                                <div className="filter-btns">
                                    <div className="filter-btn">
                                        <div className="filter-search">
                                            <div className="filter-search__text">find</div>
                                            <div className="filter-search__field">
                                                <input
                                                    type="text"
                                                    id="tournamentsearch-title"
                                                    className="field field--sm"
                                                    name="title"
                                                    placeholder="enter name"
                                                    value={filter.search}
                                                    onChange={handleSearchChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="filter-items">
                                    <div className="filter-item">
                                        <div className="filter-item__text prop">date:</div>
                                        <div className="filter-item__value">
                                            <div
                                                className={`datepicker-el js-datepicker-clear ${
                                                    filter.date ? 'selected' : ''
                                                }`}
                                            >
                                                <div style={{ height: 0 }}>
                                                    <DatePicker
                                                        customInput={<div style={{ minWidth: '40px' }} />}
                                                        selected={filter.date}
                                                        onChange={handleDateChange}
                                                        open={isOpenDatepicker}
                                                    >
                                                        <div className="datepicker--buttons">
                                                            {filter.date !== null && (
                                                                <span
                                                                    className="datepicker--button"
                                                                    onClick={() => handleDateChange(null)}
                                                                    style={{ color: '#DF0D14', fontWeight: 'bold' }}
                                                                >
                                                                    Clear
                                                                </span>
                                                            )}
                                                        </div>
                                                    </DatePicker>
                                                </div>
                                                <div
                                                    className="datepicker-el__btn"
                                                    onClick={() => setOpenDatepicker(true)}
                                                >
                                                    <div className="datepicker-el__btn-text prop">
                                                        {filter.date
                                                            ? moment(filter.date).format('MM/DD/YYYY')
                                                            : 'choose'}
                                                    </div>
                                                    <div className="datepicker-el__btn-icon">
                                                        <svg className="icon">
                                                            <use href={`${MainSymbols}#image-chevron`}></use>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="filter-item">
                                        <div className="filter-item__text prop">type:</div>
                                        <div className="filter-item__value">
                                            <div className="select">
                                                <div className="select-btn">
                                                    <Select
                                                        value={filter.type}
                                                        onChange={handleTypeChange}
                                                        options={SELECT_MEDIA_LIST_TYPE_OPTIONS}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="filter-item">
                                        <div className="filter-item__text prop">sort by:</div>
                                        <div className="filter-item__value">
                                            <div className="select">
                                                <div className="select-btn">
                                                    <Select
                                                        value={filter.order}
                                                        onChange={handleOrderChange}
                                                        options={SELECT_MEDIA_LIST_ORDER_OPTIONS}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SimpleBar>
            </div>
        </div>
    );
};
