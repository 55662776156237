import React, { useCallback, useState } from 'react';
import { PropsValue } from 'react-select';
import ReactSelect from 'react-select/base';

interface ISelectProps {
    value: PropsValue<{ value: number; label: string }>;
    onChange: (selectedOption: PropsValue<{ value: number; label: string }>) => void;
    options: { value: number; label: string }[];
    onOpen?: () => void;
}
export const Select = ({ value, onChange, options, onOpen }: ISelectProps): JSX.Element => {
    const [isOpen, setOpen] = useState<boolean>(false);

    const open = useCallback(() => {
        setOpen(true);
        if (onOpen) {
            onOpen();
        }
    }, []);

    const close = useCallback(() => {
        setOpen(false);
    }, []);

    return (
        <ReactSelect
            styles={{
                control: (baseStyles) => ({
                    ...baseStyles,
                    border: 'none',
                    background: 'none',
                    height: '28px',
                    boxShadow: 'none',
                    cursor: 'pointer',
                    color: '#F2F2F2',
                    flexWrap: 'nowrap',
                    minWidth: 'max-content',

                    svg: {
                        width: '22px',
                        height: '22px',
                        marginTop: '-3px',
                        path: {
                            fill: '#DF0D14',
                        },
                    },
                    '&:hover': {
                        color: '#DF0D14',
                        svg: {
                            path: {
                                fill: '#F2F2F2',
                            },
                        },
                    },
                }),
                singleValue: (baseStyles) => ({
                    ...baseStyles,
                    color: 'inherit',
                    textTransform: 'uppercase',
                    lineHeight: 1,
                    fontSize: '12px',
                    fontFamily: 'RobotoCondensed-Bold',
                }),
                indicatorSeparator: () => ({
                    display: 'none',
                }),
                menu: (baseStyle) => ({
                    ...baseStyle,
                    padding: 0,
                    width: 'max-content',
                    top: '20px',
                }),
                menuList: (baseStyle) => ({
                    ...baseStyle,
                    width: 'max-content',
                    padding: '5px 10px',
                }),
                option: (baseStyle, state) => ({
                    ...baseStyle,
                    color: state.isSelected ? '#DF0D14' : '#1A1A1A',
                    background: 'none',
                    padding: '5px 0',
                    textTransform: 'uppercase',
                    lineHeight: 1,
                    fontFamily: 'RobotoCondensed-Bold',
                    margin: 0,
                    whiteSpace: 'nowrap',
                    transition: '0.3s ease-in-out',
                    cursor: 'pointer',
                    fontSize: '14px',
                    '&:hover': {
                        color: '#DF0D14 !important',
                    },
                }),
                dropdownIndicator: (baseStyle) => ({
                    ...baseStyle,
                    padding: '0',
                }),
            }}
            value={value}
            onChange={onChange}
            options={options}
            isSearchable={false}
            inputValue={''}
            onInputChange={() => {
                console.log('');
            }}
            menuIsOpen={isOpen}
            onMenuClose={close}
            onMenuOpen={open}
        />
    );
};
