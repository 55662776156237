import moment from 'moment/moment';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { PropsValue } from 'react-select';

import { useRootContext } from '~app/contexts/RootContext';
import { IMedia, MediaListOrder, MediaType, SELECT_MEDIA_LIST_ORDER_OPTIONS } from '~app/types/media.interface';

import { request } from '../utils';

interface IMediaListFilter {
    search: string;
    type: { value: MediaType; label: string };
    date: Date | null;
    order: { value: MediaListOrder; label: string };
}
interface MediaContextProps {
    mediaList: IMedia[] | null;
    media: IMedia | null;
    fetchMediaList: () => Promise<void>;
    fetchMedia: (slug: string) => Promise<void>;
    filter: IMediaListFilter;
    updateFilter: (
        field: keyof IMediaListFilter,
        value: string | Date | PropsValue<{ value: number; label: string }> | null,
    ) => void;
    clearMediaData: () => void;
}

export const MediaContext = createContext<MediaContextProps>({} as MediaContextProps);

export const MediaContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const { showLoader } = useRootContext();

    const [originalMediaList, setOriginalMediaList] = useState<IMedia[] | null>(null);
    const [mediaList, setMediaList] = useState<IMedia[] | null>(null);
    const [media, setMedia] = useState<IMedia | null>(null);
    const [filter, setFilter] = useState<IMediaListFilter>({
        search: '',
        type: { value: -1, label: 'All' },
        date: null,
        order: SELECT_MEDIA_LIST_ORDER_OPTIONS[0],
    });

    const doFilterMedia = (f: IMediaListFilter, oMedia: IMedia[] | null) => {
        if (!oMedia) {
            setMediaList(null);

            return;
        }

        const filteredMediaList = oMedia
            .filter((_) => {
                if (f.search) {
                    return _.title.toLowerCase().includes(f.search.toLowerCase());
                }

                return true;
            })
            .filter((_) => {
                if (f.type.value === MediaType.TEXT) {
                    return _.is_text === 1;
                }

                if (f.type.value === MediaType.VIDEO) {
                    return _.is_video === 1;
                }

                return true;
            })
            .filter((_) => {
                if (filter.date) {
                    return moment(_.date).isSame(moment(filter.date).utc(true));
                }

                return true;
            })
            .sort((a, b) => {
                switch (filter.order.value) {
                    case MediaListOrder.DATE: {
                        if (moment(a.date).isSame(moment(b.date))) {
                            return 0;
                        }
                        return moment(a.date).isAfter(moment(b.date)) ? -1 : 1;
                    }
                    case MediaListOrder.TITLE: {
                        if (a.title < b.title) {
                            return -1;
                        }
                        if (a.title > b.title) {
                            return 1;
                        }
                        return 0;
                    }
                    default: {
                        return 0;
                    }
                }
            });

        setMediaList(filteredMediaList);
    };

    const fetchMediaList = async () => {
        showLoader();
        setOriginalMediaList(null);
        const res = await request<IMedia[]>('/media-list');
        setOriginalMediaList(res);
    };

    const fetchMedia = async (slug: string) => {
        showLoader();
        setMedia(null);
        const res = await request<IMedia>(`/media/${slug}`);
        setMedia(res);
    };

    const updateFilter = (
        field: keyof IMediaListFilter,
        value: string | Date | PropsValue<{ value: number; label: string }> | null,
    ) => {
        setFilter((prev) => {
            const newVal = { ...prev };
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            newVal[field] = value;

            return newVal;
        });
    };

    const clearMediaData = () => {
        setOriginalMediaList(null), setMediaList(null);
        setMedia(null);
        setFilter({
            search: '',
            type: { value: -1, label: 'All' },
            date: null,
            order: SELECT_MEDIA_LIST_ORDER_OPTIONS[0],
        });
    };

    useEffect(() => {
        doFilterMedia(filter, originalMediaList);
    }, [filter, originalMediaList]);

    return (
        <MediaContext.Provider
            value={{
                mediaList,
                media,
                fetchMediaList,
                fetchMedia,
                filter,
                updateFilter,
                clearMediaData,
            }}
        >
            {children}
        </MediaContext.Provider>
    );
};

export const useMediaContext = () => useContext(MediaContext);
