import React, { useEffect } from 'react';

import bg5 from '~app/assets/img/bg5.jpg';
import bg14 from '~app/assets/img/bg14.jpg';
import { useChampionsContext, useRootContext } from '~app/contexts';
import { ChampionCard } from '~app/pages/components/champion-card';
import { ChampionsFilter } from '~app/pages/components/champions-filter';
import { Empty } from '~app/pages/components/empty';

export const ChampionsPage = (): JSX.Element => {
    const { hideLoader } = useRootContext();
    const { champions, fetchChampions, clearChampionsData } = useChampionsContext();

    useEffect(() => {
        fetchChampions().then();

        return () => {
            clearChampionsData();
        };
    }, []);

    useEffect(() => {
        if (champions !== null) {
            hideLoader();
        }
    }, [champions]);

    return (
        <div id="champions-list">
            <section className="section section--head">
                <div className="section-bg">
                    <div className="section-bg__overlay">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <div className="section-bg__img" style={{ backgroundImage: `url(${bg14})` }} />
                </div>
                <div className="section-inner">
                    <div className="container">
                        <div className="section-title">
                            <h1 className="h2">
                                all champions <span>/ {champions?.length ?? '-'}</span>
                            </h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section section--main section--sm">
                <div className="section-bg">
                    <div className="section-bg__img" style={{ backgroundImage: `url(${bg5})` }} />
                </div>
                <div className="section-inner">
                    <div className="container--sm">
                        <ChampionsFilter style={{ zIndex: 2 }} />

                        {!champions?.length && <Empty />}
                        {!!champions?.length && (
                            <div className="filter-content">
                                <div className="mb">
                                    <div className="champs champs--lg">
                                        <div className="champs-inner">
                                            {champions.map((champion) => {
                                                return (
                                                    <ChampionCard champion={champion} key={champion.tournament.id} />
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        <ChampionsFilter />
                    </div>
                </div>
            </section>
        </div>
    );
};
