import React from 'react';

import bg5 from '~app/assets/img/bg5.jpg';

export const Empty = (): JSX.Element => {
    return (
        <section className="section section--main section--sm" id="empty">
            <div className="section-bg">
                <div className="section-bg__img" style={{ backgroundImage: `url(${bg5})` }}></div>
            </div>
            <div className="section-inner">
                <div className="container--sm">
                    <div className="empty">
                        <div
                            className="empty-text js-fittext"
                            style={{ whiteSpace: 'nowrap', display: 'inline-block', fontSize: '96px' }}
                        >
                            still empty here
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
