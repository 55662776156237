import moment from 'moment/moment';
import React, { useCallback, useState } from 'react';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';

import bg5 from '~app/assets/img/bg5.jpg';
import MainSymbols from '~app/assets/sprites/main.symbol.svg';
import { useTournamentsContext } from '~app/contexts';

export const TournamentSchedule = (): JSX.Element | null => {
    const { tournament } = useTournamentsContext();
    const [swiper, setSwiper] = useState<SwiperClass | null>(null);

    const next = useCallback(
        (event: React.MouseEvent<HTMLDivElement>) => {
            event.preventDefault();
            event.stopPropagation();
            swiper?.slideNext();
        },
        [swiper],
    );

    const prev = useCallback(
        (event: React.MouseEvent<HTMLDivElement>) => {
            event.preventDefault();
            event.stopPropagation();
            swiper?.slidePrev();
        },
        [swiper],
    );

    if (!tournament?.schedules?.length) {
        return null;
    }

    return (
        <section className="section section--main section--sm">
            <div className="section-bg">
                <div className="section-bg__img" style={{ backgroundImage: `url(${bg5})` }}></div>
            </div>
            <div className="section-inner">
                <div className="schedule-slider container">
                    <Swiper
                        className={
                            'js-schedule-slider swiper-container swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events w-fit'
                        }
                        modules={[Navigation]}
                        slidesPerView={'auto'}
                        onSwiper={setSwiper}
                        spaceBetween={'8px'}
                        navigation={{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        }}
                    >
                        {tournament.schedules.map((schedule) => {
                            return (
                                <SwiperSlide className="swiper-slide swiper-slide-visible" key={schedule.id}>
                                    <div className="schedule-slide">
                                        <div className="schedule-slide__top">
                                            <div className="h6 schedule-slide__title">{schedule.title}</div>
                                        </div>
                                        <div className="schedule-slide__bottom">
                                            <div className="schedule-slide__date">
                                                <div className="h6">{moment(schedule.date).format('D MMMM YYYY')}</div>
                                                <div className="prop">{schedule.time.substring(0, 5)}</div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                        <div className={`swiper-button-prev`}>
                            <div className="btn" onClick={prev}>
                                <svg className="icon">
                                    <use href={`${MainSymbols}#image-chevron`}></use>
                                </svg>
                            </div>
                        </div>
                        <div className={`swiper-button-next`}>
                            <div className="btn" onClick={next}>
                                <svg className="icon">
                                    <use href={`${MainSymbols}#image-chevron`}></use>
                                </svg>
                            </div>
                        </div>
                    </Swiper>
                </div>
            </div>
        </section>
    );
};
