import React, { useMemo } from 'react';

import { useTournamentsContext } from '~app/contexts';

export const TournamentSwissBracket = (): JSX.Element | null => {
    const { currentBracket, currentRoundId } = useTournamentsContext();

    const round = useMemo(() => {
        if (!currentBracket || !currentRoundId) {
            return null;
        }

        return currentBracket.rounds.find((_) => _.order === +currentRoundId) || null;
    }, [currentBracket, currentRoundId]);

    if (!currentBracket || !currentRoundId || !round?.duels.length) {
        return null;
    }

    return (
        <div className="section-inner">
            <div className="container--sm">
                <div className="tabs active">
                    <div className="tabs-item">
                        <div className="filter-content filter-content--sm">
                            <div className="groups">
                                <div className="group-wrap">
                                    <div className="group group--admin">
                                        <div className="group-items">
                                            {round.duels.map((duel) => {
                                                return (
                                                    <div className="group-item js-group-item" key={duel.id}>
                                                        <div className="group-item__inner">
                                                            <div className="group-item__num">{duel.order}</div>
                                                            <div className="group-item__results">
                                                                <div
                                                                    className={`group-item__result js-group-result ${
                                                                        duel.player1.winner &&
                                                                        duel.player1.score !== null
                                                                            ? 'brackets-item--winner'
                                                                            : ''
                                                                    }`}
                                                                >
                                                                    <div
                                                                        className="brackets-item__title"
                                                                        style={{ color: duel.player1.color }}
                                                                    >
                                                                        {duel.player1.external_link && (
                                                                            <a
                                                                                href={duel.player1.external_link}
                                                                                className="no-decor"
                                                                                target="_blank"
                                                                                rel="noreferrer"
                                                                            >
                                                                                {duel.player1.name}
                                                                            </a>
                                                                        )}
                                                                        {!duel.player1.external_link &&
                                                                            duel.player1.name}
                                                                    </div>
                                                                    <div className="group-item__score">
                                                                        {duel.player1.score || '-'}
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className={`group-item__result js-group-result ${
                                                                        duel.player2.winner &&
                                                                        duel.player2.score !== null
                                                                            ? 'brackets-item--winner'
                                                                            : ''
                                                                    }`}
                                                                >
                                                                    <div
                                                                        className="brackets-item__title"
                                                                        style={{ color: duel.player2.color }}
                                                                    >
                                                                        {duel.player2.external_link && (
                                                                            <a
                                                                                href={duel.player2.external_link}
                                                                                className="no-decor"
                                                                                target="_blank"
                                                                                rel="noreferrer"
                                                                            >
                                                                                {duel.player2.name}
                                                                            </a>
                                                                        )}
                                                                        {!duel.player2.external_link &&
                                                                            duel.player2.name}
                                                                    </div>
                                                                    <div className="group-item__score">
                                                                        {duel.player2.score || '-'}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
