// import './normalize.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import './styles/main.scss';
import 'swiper/css';
import 'swiper/css/navigation';
import 'simplebar-react/dist/simplebar.min.css';
import 'react-tooltip/dist/react-tooltip.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import {
    ChampionsContextProvider,
    Compose,
    MainContextProvider,
    MediaContextProvider,
    RootContextProvider,
    TournamentsContextProvider,
} from '~app/contexts';

import App from './app';
import i18n from './i18n';
import reportWebVitals from './reportWebVitals';

window.addEventListener('DOMContentLoaded', () => {
    const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

    root.render(
        <BrowserRouter>
            <Compose
                components={[
                    RootContextProvider,
                    MainContextProvider,
                    TournamentsContextProvider,
                    MediaContextProvider,
                    ChampionsContextProvider,
                ]}
            >
                <I18nextProvider i18n={i18n}>
                    <App />
                </I18nextProvider>
            </Compose>
        </BrowserRouter>,
    );

    reportWebVitals();
});
