import React, { useCallback, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import SimpleBar from 'simplebar-react';

import bg1 from '~app/assets/img/bg1.jpg';
import bg2 from '~app/assets/img/bg2.jpg';
import bg3 from '~app/assets/img/bg3.jpg';
import champHead from '~app/assets/img/champ-head.png';
import sectionImg2PNG from '~app/assets/img/section-img2.png';
import sectionImg2Webp from '~app/assets/img/section-img2.webp';
import trailer from '~app/assets/img/trailer.jpg';
import trailerWebp from '~app/assets/img/trailer.webp';
import type1 from '~app/assets/img/type1.jpg';
import type1Webp from '~app/assets/img/type1.webp';
import MainSymbols from '~app/assets/sprites/main.symbol.svg';
import { useMainContext, useRootContext } from '~app/contexts';
import { MediaCard } from '~app/pages/components/media-card';
import { IMedia } from '~app/types/media.interface';

import { ChampionCard } from './components/champion-card';
import { TournamentCard } from './components/tournament-card';

export const MainPage = (): JSX.Element => {
    const { settingsDict, hideLoader, tournamentTypes, showLoader } = useRootContext();
    const { statistics, fetchStatistics, clearMainData } = useMainContext();

    const videoContainerRef = useRef<HTMLDivElement>(null);
    const vidRef = useRef<HTMLVideoElement>(null);

    const playVideo = useCallback(() => {
        if (!vidRef.current || !videoContainerRef.current) {
            return;
        }

        videoContainerRef.current.className = 'video active';
        vidRef.current.load();
        vidRef.current.play().then();
    }, []);

    useEffect(() => {
        if (settingsDict !== null && statistics !== null && tournamentTypes !== null) {
            hideLoader();
        }
    }, [settingsDict, statistics, tournamentTypes]);

    useEffect(() => {
        fetchStatistics().then();

        return () => {
            clearMainData();
        };
    }, []);

    return (
        <>
            <section className="section section--hero">
                <div className="section-bg">
                    <div className="section-bg__overlay">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <div className="section-bg__img" style={{ backgroundImage: `url(${bg1})` }}></div>
                    <div className="section-img">
                        <picture>
                            <source srcSet={sectionImg2Webp} type="image/webp" />
                            <source srcSet={sectionImg2PNG} />
                            <img src={sectionImg2PNG} alt="" />
                        </picture>
                    </div>
                </div>

                {settingsDict && (
                    <div className="section-inner">
                        <div className="container">
                            <div className="section-row">
                                <div className="section-col">
                                    <h1 dangerouslySetInnerHTML={{ __html: settingsDict.home_intro_title }} />
                                    <div
                                        className="descr"
                                        dangerouslySetInnerHTML={{ __html: settingsDict.home_intro_desc }}
                                    />
                                    <a className="btn" href={settingsDict.home_intro_btn_link}>
                                        {settingsDict.home_intro_btn_text}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </section>

            <section className="section">
                <div className="section-inner">
                    <div className="section-head">
                        <div className="container--sm">
                            <div className="section-head__container">
                                <h2 className="h3 section-head__title">latest tournaments</h2>
                                <Link className="section-head__link" to="/tournaments" onClick={showLoader}>
                                    All tournaments
                                    <svg className="icon">
                                        <use href={`${MainSymbols}#image-chevron`}></use>
                                    </svg>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="section-content">
                        <div className="container">
                            <SimpleBar className="tourneys">
                                <div className="tourneys-inner">
                                    {statistics !== null &&
                                        statistics.tournaments?.map((tournament) => (
                                            <TournamentCard tournament={tournament} key={tournament.id} />
                                        ))}
                                </div>
                            </SimpleBar>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section">
                <div className="section-bg">
                    <div className="section-bg__img" style={{ backgroundImage: `url(${bg2})` }}></div>
                </div>
                <div className="section-inner">
                    <div className="section-head">
                        <div className="container--sm">
                            <div className="section-head__container">
                                <h2 className="h3 section-head__title">Choose your type</h2>
                                <Link className="section-head__link" to="/tournaments" onClick={showLoader}>
                                    All tournaments
                                    <svg className="icon">
                                        <use href={`${MainSymbols}#image-chevron`}></use>
                                    </svg>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="section-content">
                        <div className="container--sm">
                            <div className="types">
                                {Array.isArray(tournamentTypes) &&
                                    tournamentTypes.slice(0, 6).map((tournamentType) => {
                                        return (
                                            <Link
                                                className="type"
                                                to={`/tournaments?type_slug=${tournamentType.slug}`}
                                                key={tournamentType.id}
                                                onClick={showLoader}
                                            >
                                                <div className="type-inner">
                                                    <div className="type-media">
                                                        <picture>
                                                            <source srcSet={type1Webp} type="image/webp" />
                                                            <source srcSet={type1} />
                                                            <img className="type-img" src={type1} alt="" />
                                                        </picture>
                                                    </div>
                                                    <div className="type-content">
                                                        <h4 className="type-title">{tournamentType.name}</h4>
                                                        <div className="type-tag">
                                                            <div className="tag tag--dark">
                                                                {tournamentType.description}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section">
                <div className="section-inner">
                    <div className="section-head">
                        <div className="container--sm">
                            <div className="section-head__container">
                                <h2 className="h3 section-head__title">hall of fame</h2>
                                <Link className="section-head__link" to="/champions" onClick={showLoader}>
                                    All champions
                                    <svg className="icon">
                                        <use href={`${MainSymbols}#image-chevron`}></use>
                                    </svg>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="section-content">
                        <div className="container">
                            <SimpleBar className="champs">
                                <div className="champs-inner">
                                    <div className="champs-item">
                                        <div className="champs-head">
                                            <div className="champs-head__media">
                                                <picture>
                                                    {/*<source srcSet={champHeadWebp} type="image/webp" />*/}
                                                    <source srcSet={champHead} />
                                                    <img className="champs-head__img" src={champHead} alt="" />
                                                </picture>
                                            </div>
                                            <div className="champs-head__content">
                                                <div className="champs-head__title h1">you might be here</div>
                                                <Link className="btn" to="/tournaments">
                                                    find your tournament
                                                </Link>
                                            </div>
                                        </div>
                                    </div>

                                    {statistics?.champions?.map((champion) => {
                                        return (
                                            <ChampionCard
                                                champion={champion}
                                                key={`${champion.tournament.id}_${
                                                    champion.player?.id ?? champion.team?.id
                                                }`}
                                            />
                                        );
                                    })}
                                </div>
                            </SimpleBar>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section">
                <div className="section-bg">
                    <div className="section-bg__img" style={{ backgroundImage: `url(${bg3})` }}></div>
                </div>
                <div className="section-inner">
                    <div className="container">
                        <div className="section-row">
                            {!!settingsDict && (
                                <div className="section-col">
                                    <h2 className="h1">{settingsDict.home_trailer_title}</h2>
                                    <div
                                        className="descr"
                                        dangerouslySetInnerHTML={{ __html: settingsDict.home_trailer_desc }}
                                    />
                                    <Link
                                        className="btn"
                                        target="_blank"
                                        rel="nofollow"
                                        to={settingsDict.home_trailer_link}
                                    >
                                        watch on youtube
                                    </Link>
                                </div>
                            )}
                            <div className="section-col">
                                <div className="video" ref={videoContainerRef}>
                                    <div className="video-btn" onClick={playVideo}>
                                        <svg className="icon">
                                            <use href={`${MainSymbols}#image-play`}></use>
                                        </svg>
                                    </div>
                                    <div className="video-bg">
                                        <picture>
                                            <source srcSet={trailerWebp} type="image/webp" />
                                            <source srcSet={trailer} />
                                            <img src={trailer} alt="" />
                                        </picture>
                                    </div>
                                    <div className="video-content">
                                        <video ref={vidRef} src="/assets/vid.mp4" controls></video>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section">
                <div className="section-inner">
                    <div className="section-head">
                        <div className="container--sm">
                            <div className="section-head__container">
                                <h2 className="h3 section-head__title">media</h2>
                                <Link className="section-head__link" to="/media" onClick={showLoader}>
                                    All media
                                    <svg className="icon">
                                        <use href={`${MainSymbols}#image-chevron`}></use>
                                    </svg>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="section-content">
                        <div className="container">
                            <SimpleBar className="news">
                                <div className="news-inner">
                                    {statistics?.media?.map((item: IMedia) => <MediaCard key={item.id} media={item} />)}
                                </div>
                            </SimpleBar>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
