import dayjs from 'dayjs';
import dayEn from 'dayjs/locale/en';
import dayRu from 'dayjs/locale/ru';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { en, ru } from './translations';

function defLng(): 'ru' | 'en' {
    const l = localStorage.getItem('i18nextLng') ?? 'en';
    if (['ru', 'en'].includes(l)) {
        return l as 'ru' | 'en';
    }

    return 'en';
}

i18n.on('languageChanged', (lng: string) => {
    if (lng === 'ru') {
        return dayjs.locale(dayRu);
    }

    if (lng === 'en') {
        return dayjs.locale(dayEn);
    }

    return undefined;
});

i18n.use(LanguageDetector).init({
    resources: {
        ru,
        en,
    },

    lng: defLng(),
    fallbackLng: 'en',
    whitelist: ['ru', 'en'],
    load: 'languageOnly',
    nonExplicitWhitelist: true,
    nsSeparator: false,
    debug: false,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    // keySeparator: false, // we use content as keys

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ',',
    },

    react: {
        wait: true,
        useSuspense: false,
    },
});

export default i18n;
