import React from 'react';
import { ToastContainer } from 'react-toastify';

import { Pages } from './pages';

export default (): JSX.Element => {
    return (
        <>
            <Pages />
            <ToastContainer />
        </>
    );
};
