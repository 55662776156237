export enum PrizeType {
    STANDARD,
    SPECIAL,
    SECONDARY,
}

export interface IPrize {
    description: string;
    id: number;
    money: string;
    tournament_id?: string;
    type_id: PrizeType;
}
