import { IPlayer } from '~app/types/player.interface';
import { ITeam } from '~app/types/team.interface';
import { ITournament } from '~app/types/tournament.interface';

export enum ChampionOrder {
    DATE,
    POOL,
    TYPE,
    STATUS,
    TITLE,
}

export const SELECT_CHAMPIONS_ORDER_OPTIONS = [
    { value: ChampionOrder.DATE, label: 'Date' },
    { value: ChampionOrder.POOL, label: 'Prize pool' },
    { value: ChampionOrder.TYPE, label: 'Type' },
    { value: ChampionOrder.STATUS, label: 'Status' },
    { value: ChampionOrder.TITLE, label: 'Title' },
];

export interface IChampion {
    player?: Partial<IPlayer> | null;
    team?: Partial<ITeam> | null;
    tournament: Partial<ITournament>;
}
